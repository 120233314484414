import { useNode, UserComponent } from '@craftjs/core';
import { cva, VariantProps } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'usehooks-ts';

import { breakpoints } from '../../../constants/breakpoints';
import { fadeInProps } from '../../CustomLandingPage/animations';
import { ContainerSettings } from './ContainerSettings';
import { cn } from 'util/cn';

const containerVariants = cva('box-border flex', {
  variants: {
    orientation: {
      row: 'flex-row',
      column: 'flex-col',
    },
    gap: {
      '0': 'gap-0',
      px: 'gap-px',
      '.25': 'gap-.25',
      '.5': 'gap-.5',
      '1': 'gap-1',
      '2': 'gap-2',
      '3': 'gap-3',
      '4': 'gap-4',
      '5': 'gap-5',
      '6': 'gap-6',
      '7': 'gap-7',
      '8': 'gap-8',
      '9': 'gap-9',
      '10': 'gap-10',
    },
    justifyContent: {
      start: 'justify-start',
      end: 'justify-end',
      center: 'justify-center',
      between: 'justify-between',
      around: 'justify-around',
      evenly: 'justify-evenly',
    },
    alignItems: {
      start: 'items-start',
      end: 'items-end',
      center: 'items-center',
      baseline: 'items-baseline',
      stretch: 'items-stretch',
    },
    background: {
      black: 'bg-black',
      white: 'bg-white',
    },
  },
  defaultVariants: {
    orientation: 'column',
  },
});

export type ContainerProps = React.PropsWithChildren<
  VariantProps<typeof containerVariants> & {
    className?: string;
    container?: boolean;
    customBackground?: string;
    backgroundImage?: string;
    responsiveMinScreen?: string;
    responsiveMaxScreen?: string;
    enableFadeIn?: boolean;
    maxWidth?: string;
  }
>;

export const Container: UserComponent<ContainerProps> = ({
  className,
  container,
  responsiveMinScreen,
  responsiveMaxScreen,
  ...props
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  const minScreenValue = responsiveMinScreen
    ? breakpoints[responsiveMinScreen] || responsiveMinScreen
    : '0px';
  const maxScreenValue = responsiveMaxScreen
    ? breakpoints[responsiveMaxScreen] || responsiveMaxScreen
    : 'Infinity';

  const isAboveMin = useMediaQuery(`(min-width: ${minScreenValue})`);
  const isBelowMax = useMediaQuery(
    maxScreenValue === 'Infinity' ? '(min-width: 0px)' : `(max-width: ${maxScreenValue})`
  );

  if (!isAboveMin || !isBelowMax) {
    return null;
  }

  const Comp = props.enableFadeIn
    ? (props: any) => <motion.div {...props} {...fadeInProps} />
    : 'div';

  return (
    <Comp
      ref={ref => ref && connect(drag(ref))}
      className={cn(
        containerVariants(props),
        container ? 'max-w-[var(--n-content-max-width)] px-3 lg:px-10 container mx-auto' : '',
        props.backgroundImage && 'bg-cover bg-center bg-no-repeat',
        className
      )}
      style={{
        ...(props.backgroundImage ? { backgroundImage: `url(${props.backgroundImage})` } : {}),
        ...(props.customBackground ? { background: props.customBackground } : {}),
        ...(props.maxWidth ? { maxWidth: props.maxWidth } : {}),
      }}
    >
      {props.children}
    </Comp>
  );
};

Container.craft = {
  displayName: 'Container',
  related: {
    settings: ContainerSettings,
  },
};
