import { useNode } from '@craftjs/core';

import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';

export const AccordionSectionSettings = () => {
  const {
    actions: { setProp },
    title,
    items,
    showTopBorder,
  } = useNode(node => ({
    title: node.data.props.title,
    items: node.data.props.items,
    showTopBorder: node.data.props.showTopBorder,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Accordion Section Settings</div>

      <label>
        Title
        <input
          type="text"
          value={title}
          onChange={e => setProp((props: any) => (props.title = e.target.value))}
          className="w-full p-1"
        />
      </label>

      <label>
        Show Top Border
        <Checkbox
          checked={showTopBorder}
          onCheckedChange={value => setProp((props: any) => (props.showTopBorder = value))}
        />
      </label>

      <div>
        <div className="font-bold mb-2">Items</div>
        {items.map((item: any, index: number) => (
          <div key={index} className="mb-4 p-2 border border-gray-200">
            <label>
              Label
              <input
                type="text"
                value={item.label}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].label = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <label>
              Content
              <textarea
                value={item.content}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].content = e.target.value;
                  })
                }
                className="w-full p-1"
                rows={4}
              />
            </label>
            <div className="flex gap-2 mt-2">
              <button
                onClick={() =>
                  setProp((props: any) => {
                    if (index > 0) {
                      const newItems = [...props.items];
                      [newItems[index - 1], newItems[index]] = [
                        newItems[index],
                        newItems[index - 1],
                      ];
                      props.items = newItems;
                    }
                  })
                }
                disabled={index === 0}
                className="bg-blue-500 text-white px-2 py-1 rounded disabled:opacity-50"
              >
                ↑ Move Up
              </button>
              <button
                onClick={() =>
                  setProp((props: any) => {
                    if (index < props.items.length - 1) {
                      const newItems = [...props.items];
                      [newItems[index], newItems[index + 1]] = [
                        newItems[index + 1],
                        newItems[index],
                      ];
                      props.items = newItems;
                    }
                  })
                }
                disabled={index === items.length - 1}
                className="bg-blue-500 text-white px-2 py-1 rounded disabled:opacity-50"
              >
                ↓ Move Down
              </button>
              <button
                onClick={() =>
                  setProp((props: any) => {
                    props.items = props.items.filter((_: any, i: number) => i !== index);
                  })
                }
                className="bg-red-500 text-white px-2 py-1 rounded"
              >
                Remove Item
              </button>
            </div>
          </div>
        ))}
        <button
          onClick={() =>
            setProp((props: any) => {
              props.items = [
                ...props.items,
                {
                  label: 'New Question',
                  content: 'New Answer',
                },
              ];
            })
          }
          className="bg-green-500 text-white px-2 py-1 rounded"
        >
          Add Item
        </button>
      </div>
    </div>
  );
};
