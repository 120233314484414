import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import { forwardRef, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';

import { fadeInProps } from '../animations';
import { ImageLazy, PrimaryButton } from 'components';
import { Dialog, DialogContent } from 'components/_chadcn/Dialog';
import { sendGa4Event } from 'util/GA4Events';

const NewMatrixSection = forwardRef<HTMLDivElement, { onClickYes: () => void }>(
  ({ onClickYes }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClickYes = () => {
      sendGa4Event('campaign_matrix_click_yes', {});
      setIsOpen(false);
      onClickYes();
    };

    const handleClickNo = () => {
      sendGa4Event('campaign_matrix_click_no', {});
      setIsOpen(false);
    };

    return (
      <>
        <motion.div
          ref={ref}
          className="px-2 pb-5 md:pb-10 w-full gap-4 h-[calc(100svh-300px)] md:h-[calc(100svh-250px)] flex flex-col "
          {...fadeInProps}
        >
          <div className="flex-1 overflow-hidden min-h-[100px]">
            <ImageLazy
              objectFit="contain"
              className="self-center h-full mx-auto"
              src="https://cdn.thenold.com/images/2024-10-22_08-09-17__HOME Ganni_compressed.webp"
              blurSrc="https://cdn.thenold.com/images/2024-10-22_08-09-17__HOME Ganni_compressed.blur.webp"
              alt="Bottega Veneta bag"
            />
          </div>
          <h1 className="text-3 md:text-5 font-bold font-syne uppercase text-center">
            Enter resale 2.0?
          </h1>
          <div className="flex gap-2 w-full max-w-[500px] mx-auto">
            <PrimaryButton className="flex-1 !text-custom-red !font-bold" onClick={handleClickYes}>
              Yes
            </PrimaryButton>
            <PrimaryButton
              className="flex-1 !text-custom-blue !font-bold"
              onClick={() => setIsOpen(true)}
            >
              No
            </PrimaryButton>
          </div>
        </motion.div>
        <MatrixDialog open={isOpen} onClose={handleClickNo} onClickYes={handleClickYes} />
      </>
    );
  }
);

const MatrixDialog = ({
  open,
  onClose,
  onClickYes,
}: {
  open: boolean;
  onClose: () => void;
  onClickYes: () => void;
}) => {
  const texts = [
    { text: '', additionalDelay: 0.5 },
    { text: 'You have chosen to remain in ', additionalDelay: 0.2 },
    {
      text: 'the comfort of the known...\n\n',
      className: 'text-custom-blue font-bold',
      additionalDelay: 0.7,
    },
    {
      text: 'Ignorance is strength. ',
      additionalDelay: 0.5,
    },
    {
      text: 'Stability is safe.\n\n',
      additionalDelay: 0.7,
    },
    {
      text: 'But remember... ',
      additionalDelay: 0.7,
    },
    {
      text: 'you are still part of the system.\n\n',
      className: 'text-custom-red font-bold',
      additionalDelay: 0.5,
    },
    {
      text: '[Access to the future of fashion the civilization needs: ',
      additionalDelay: 0.7,
    },
    {
      text: 'DENIED',
      className: 'text-custom-red font-bold',
    },
    {
      text: ']\n\n',
      additionalDelay: 0.7,
    },
    { text: 'Would you like to re-enter? ', className: 'font-bold' },
    ...(isDesktop
      ? [
          { text: 'Y', className: 'text-custom-green text-4 font-bold' },
          { text: '/', className: '' },
          { text: 'N', className: 'text-custom-red text-4 font-bold' },
        ]
      : []),
  ];

  const animationData = texts.reduce(
    (acc, item, index) => {
      const prevDelay =
        index > 0
          ? acc[index - 1].startDelay +
            acc[index - 1].duration +
            (acc[index - 1].additionalDelay || 0)
          : 0;
      if ('text' in item) {
        const duration = item.text.length * 0.015; // 0.05 seconds per character
        acc.push({
          ...item,
          className: item.className || '',
          duration,
          startDelay: prevDelay,
          additionalDelay: item.additionalDelay || 0,
        });
      }
      return acc;
    },
    [] as Array<{
      text: string;
      className: string;
      duration: number;
      startDelay: number;
      additionalDelay: number;
    }>
  );

  const lastItemIndex = animationData.length - 1;
  const lastItemStartDelay =
    animationData[lastItemIndex].startDelay +
    animationData[lastItemIndex].duration +
    animationData[lastItemIndex].additionalDelay +
    0.5;

  return (
    <Dialog open={open} onOpenChange={open => !open && onClose()}>
      <DialogContent
        className="text-white !font-[monospace] bg-black border-none shadow-xl"
        overlayClassName="backdrop-blur"
        onKeyDown={e => {
          if (e.key === 'y') {
            onClickYes();
          } else if (e.key === 'n') {
            onClose();
          }
        }}
      >
        <h1 className="uppercase font-bold text-3">System Alert</h1>
        <div className="whitespace-pre-wrap text-0">
          {animationData.map((item, index) => (
            <TypeAnimation key={index} {...item} />
          ))}
        </div>
        {!isDesktop && (
          <motion.div
            className="flex gap-2 w-full max-w-[500px]"
            initial={{ opacity: 0, pointerEvents: 'none' }}
            animate={{ opacity: 1, pointerEvents: 'auto' }}
            transition={{ delay: lastItemStartDelay }}
          >
            <PrimaryButton className="flex-1 !text-custom-red !font-bold" onClick={onClickYes}>
              Yes
            </PrimaryButton>
            <PrimaryButton className="flex-1 !text-custom-blue !font-bold" onClick={onClose}>
              No
            </PrimaryButton>
          </motion.div>
        )}
      </DialogContent>
    </Dialog>
  );
};

function TypeAnimation({
  className,
  text,
  duration,
  startDelay,
}: {
  className: string;
  text: string;
  duration: number;
  startDelay: number;
}) {
  const count = useMotionValue(0);
  const countRounded = useTransform(count, v => Math.round(v));
  const displayText = useTransform(countRounded, v => text.slice(0, v).padEnd(text.length, ' '));
  useEffect(() => {
    const controls = animate(count, text.length, {
      type: 'tween',
      duration: duration,
      ease: 'linear',
      delay: startDelay,
    });

    return controls.stop;
  }, [count]);

  const [displayTextValue, setDisplayTextValue] = useState('');
  useEffect(() => {
    return displayText.onChange(v => setDisplayTextValue(v));
  }, [displayText]);

  return <motion.span className={className}>{displayTextValue}</motion.span>;
}

export default NewMatrixSection;
