import { useNode } from '@craftjs/core';
import { ComponentProps } from 'react';

import NewTrendingNowSection from 'containers/CustomLandingPage/components/NewTrendingNowSection';

function TrendingNowSectionWrapper(props: ComponentProps<typeof NewTrendingNowSection>) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return <NewTrendingNowSection {...props} ref={ref => ref && connect(drag(ref))} />;
}

TrendingNowSectionWrapper.craft = {
  displayName: 'Trending Now',
};

export default TrendingNowSectionWrapper;
