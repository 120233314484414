import { useNode, UserComponent } from '@craftjs/core';
import { cva, VariantProps } from 'class-variance-authority';
import React, { PropsWithChildren } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { breakpoints } from '../../../constants/breakpoints';
import { TextSettings } from './TextSettings';
import { cn } from 'util/cn';

const textVariants = cva('box-border', {
  variants: {
    font: {
      unset: '',
      montserrat: 'font-montserrat',
      syne: 'font-syne',
    },
    size: {
      unset: '',
      '0000': 'text-0000',
      '000': 'text-000',
      '00': 'text-00',
      '0-in-px': 'text-0-in-px',
      '0': 'text-0',
      '1': 'text-1',
      '2': 'text-2',
      '3': 'text-3',
      '4': 'text-4',
      '5': 'text-5',
      '6': 'text-6',
      '7': 'text-7',
    },
    casing: {
      default: 'normal-case',
      uppercase: 'uppercase',
      lowercase: 'lowercase',
      capitalize: 'capitalize',
    },
    weight: {
      unset: '',
      extralight: 'font-extralight',
      light: 'font-light',
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      extrabold: 'font-extrabold',
      black: 'font-black',
    },
    color: {
      unset: '',
      black: 'text-black',
      white: 'text-white',
      custom: '',
    },
    lineHeight: {
      none: 'leading-none',
      tight: 'leading-tight',
      snug: 'leading-snug',
      normal: 'leading-normal',
      relaxed: 'leading-relaxed',
      loose: 'leading-loose',
    },
    strikethrough: {
      true: 'line-through',
      false: '',
    },
    backgroundColor: {
      unset: '',
      'custom-green': 'bg-custom-green',
      black: 'bg-black',
      custom: '',
    },
    whitespace: {
      normal: 'whitespace-normal',
      nowrap: 'whitespace-nowrap',
      pre: 'whitespace-pre',
      'pre-line': 'whitespace-pre-line',
      'pre-wrap': 'whitespace-pre-wrap',
      break: 'whitespace-break-spaces',
    },
    textAlign: {
      unset: '',
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
      justify: 'text-justify',
    },
  },
  defaultVariants: {
    font: 'unset',
    size: 'unset',
    casing: 'default',
    color: 'unset',
    weight: 'unset',
    lineHeight: 'normal',
  },
});

type P = PropsWithChildren<{
  className?: string;
  as?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  style?: React.CSSProperties;
  customColor?: string;
  customBackgroundColor?: string;
  responsiveMinScreen?: string;
  responsiveMaxScreen?: string;
  width?: 'full' | number;
}> &
  VariantProps<typeof textVariants>;

export const Text: UserComponent<P> = ({
  style,
  children = 'Text',
  as,
  className,
  responsiveMinScreen,
  responsiveMaxScreen,
  width = 'full',
  ...props
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  const Comp = as || 'span';

  const minScreenValue = responsiveMinScreen
    ? breakpoints[responsiveMinScreen] || responsiveMinScreen
    : '0px';
  const maxScreenValue = responsiveMaxScreen
    ? breakpoints[responsiveMaxScreen] || responsiveMaxScreen
    : 'Infinity';

  const isAboveMin = useMediaQuery(`(min-width: ${minScreenValue})`);
  const isBelowMax = useMediaQuery(
    maxScreenValue === 'Infinity' ? '(min-width: 0px)' : `(max-width: ${maxScreenValue})`
  );

  if (!isAboveMin || !isBelowMax) {
    return null;
  }

  return (
    <Comp
      ref={ref => ref && connect(drag(ref))}
      className={cn(textVariants(props), className, width === 'full' && 'w-full')}
      style={{
        ...(props.color === 'custom' ? { color: props.customColor || 'red' } : {}),
        ...(props.backgroundColor === 'custom'
          ? { backgroundColor: props.customBackgroundColor }
          : {}),
        ...(typeof width === 'number' ? { width: `${width}px` } : {}),
      }}
    >
      {children}
    </Comp>
  );
};

Text.craft = {
  displayName: 'Text',
  related: {
    settings: TextSettings,
  },
};
