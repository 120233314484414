import classNames from 'classnames';

import css from './PiferiCustomSection.module.css';
import { Button, ImageLazy, NamedLink } from 'components';
import { ConditionType } from 'config/configListing';
import { PIFERI_BRAND_ID } from 'containers/PiferiLandingPage/PiferiLandingPage';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { DEFAULT_SORTING } from 'util/constants';

const PiferiCustomSection = () => {
  const isDesktop = useMediaQueries({ viewport: 'medium' });

  const text = (
    <div className={css.text}>
      <h2>
        Sell your piferi, <br />
        Get 100%
      </h2>
      <div className={css.text__paragraphContainer}>
        <p>1. Click on RE-SELL and enjoy the fastest PIFERI Al listing globally</p>
        <p>2. Sell and ship your item</p>
        <p>3. Claim 90% of the resale in cash or 100% in store credit for piferi.com</p>
      </div>
      <NamedLink name="ResellLandingPage" to={{ search: `?brandId=${PIFERI_BRAND_ID}` }}>
        <Button>RE-SELL</Button>
      </NamedLink>
    </div>
  );

  return (
    <div className={css.root}>
      <div className={css.shopCircular}>
        <h2>
          Shop
          <br />
          circular
        </h2>
        <NamedLink name="SearchPage" to={{ search: `?brandIds=${PIFERI_BRAND_ID}` }}>
          <Button>Shop all</Button>
        </NamedLink>
      </div>
      <NamedLink
        name="SearchPage"
        to={{
          search: `?brandIds=${PIFERI_BRAND_ID}&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
        }}
        className={classNames(css.imageContainer, css.cardText)}
      >
        <ImageLazy
          src="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_compressed.webp"
          blurSrc="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_blur.webp"
        />
      </NamedLink>
    </div>
  );
};

export default PiferiCustomSection;
