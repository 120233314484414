import { useNode } from '@craftjs/core';
import { useState } from 'react';

import FieldCheckbox from 'components/FieldCheckboxV2/FieldCheckbox';

export const ContainerSettings = () => {
  const {
    actions: { setProp },
    orientation,
    gap,
    container,
    justifyContent,
    alignItems,
    background,
    customBackground,
    backgroundImage,
    responsiveMinScreen,
    responsiveMaxScreen,
    enableFadeIn,
    maxWidth,
  } = useNode(node => ({
    orientation: node.data.props.orientation,
    gap: node.data.props.gap,
    container: node.data.props.container,
    justifyContent: node.data.props.justifyContent,
    alignItems: node.data.props.alignItems,
    background: node.data.props.background,
    customBackground: node.data.props.customBackground,
    backgroundImage: node.data.props.backgroundImage,
    responsiveMinScreen: node.data.props.responsiveMinScreen,
    responsiveMaxScreen: node.data.props.responsiveMaxScreen,
    enableFadeIn: node.data.props.enableFadeIn,
    maxWidth: node.data.props.maxWidth,
  }));

  const [backgroundType, setBackgroundType] = useState(() => {
    if (backgroundImage) return 'image';
    if (customBackground) return 'custom';
    return background || 'none';
  });

  return (
    <div className="flex flex-col gap-2">
      <div>Container settings</div>

      <FieldCheckbox
        label="Enable Fade In Animation"
        checked={enableFadeIn}
        onCheckedChange={checked => setProp((props: any) => (props.enableFadeIn = checked))}
      />

      <label>
        Orientation
        <select
          value={orientation}
          onChange={e => setProp((props: any) => (props.orientation = e.target.value))}
        >
          <option value="row">Row</option>
          <option value="column">Column</option>
        </select>
      </label>

      <label>
        Gap
        <select value={gap} onChange={e => setProp((props: any) => (props.gap = e.target.value))}>
          <option value="0">0</option>
          <option value="px">px</option>
          <option value=".25">.25</option>
          <option value=".5">.5</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </label>

      <FieldCheckbox
        label="Content Wrapper"
        checked={container}
        onCheckedChange={checked => setProp((props: any) => (props.container = checked))}
      />
      <div className="text-00 text-gray-500 -mt-1 max-w-[300px]">
        Content wrapper adds max-width, horizontal padding, and centers the container. Use this for
        main content sections that should align with the site's content width.
      </div>

      <label>
        Justify Content
        <select
          value={justifyContent}
          onChange={e => setProp((props: any) => (props.justifyContent = e.target.value))}
        >
          <option value="start">Start</option>
          <option value="end">End</option>
          <option value="center">Center</option>
          <option value="between">Space Between</option>
          <option value="around">Space Around</option>
          <option value="evenly">Space Evenly</option>
        </select>
      </label>

      <label>
        Align Items
        <select
          value={alignItems}
          onChange={e => setProp((props: any) => (props.alignItems = e.target.value))}
        >
          <option value="start">Start</option>
          <option value="end">End</option>
          <option value="center">Center</option>
          <option value="baseline">Baseline</option>
          <option value="stretch">Stretch</option>
        </select>
      </label>

      <label>
        Background
        <select
          value={backgroundType}
          onChange={e => {
            const value = e.target.value;
            setBackgroundType(value);
            setProp((props: any) => {
              props.background = value === 'black' || value === 'white' ? value : undefined;
              props.customBackground = undefined;
              props.backgroundImage = undefined;
            });
          }}
        >
          <option value="none">None</option>
          <option value="black">Black</option>
          <option value="white">White</option>
          <option value="custom">Custom</option>
          <option value="image">Image</option>
        </select>
      </label>

      {backgroundType === 'custom' && (
        <div className="grid gap-1">
          <input
            type="text"
            value={customBackground || ''}
            onChange={e => setProp((props: any) => (props.customBackground = e.target.value))}
            placeholder="Enter CSS background value"
          />
          <a
            href="https://cssgradient.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline text-00 max-w-[300px]"
          >
            You can use CSS gradients here. Paste the value `linear-gradient(...)`
          </a>
        </div>
      )}

      {backgroundType === 'image' && (
        <input
          type="text"
          value={backgroundImage || ''}
          onChange={e => setProp((props: any) => (props.backgroundImage = e.target.value))}
          placeholder="Enter image URL"
        />
      )}

      <label>
        Responsive Min Screen
        <input
          type="text"
          value={responsiveMinScreen || ''}
          onChange={e => setProp((props: any) => (props.responsiveMinScreen = e.target.value))}
          placeholder="0px (default)"
        />
        <small className="max-w-[300px] inline-block mt-1">
          Enter a pixel value (e.g., 768px) or a breakpoint name (xs, sm, md, lg, xl). The component
          will be hidden below this screen size. Default: '0px' (always visible).
        </small>
      </label>

      <label>
        Responsive Max Screen
        <input
          type="text"
          value={responsiveMaxScreen || ''}
          onChange={e => setProp((props: any) => (props.responsiveMaxScreen = e.target.value))}
          placeholder="Infinity (default)"
        />
        <small className="max-w-[300px] inline-block mt-1">
          Enter a pixel value (e.g., 1024px), 'Infinity', or a breakpoint name (xs, sm, md, lg, xl).
          The component will be hidden above this screen size. Default: 'Infinity' (always visible).
        </small>
      </label>

      <label>
        Max Width
        <input
          type="text"
          value={maxWidth || ''}
          onChange={e => setProp((props: any) => (props.maxWidth = e.target.value))}
          placeholder="e.g., 1200px"
        />
        <small className="max-w-[300px] inline-block mt-1">
          Enter a CSS width value (e.g., 1200px, 80%, etc.). Leave empty for no max width.
        </small>
      </label>
    </div>
  );
};
