import { motion } from 'framer-motion';
import { forwardRef } from 'react';

import { fadeInProps } from '../animations';
import { NewDesktopSection } from './NewDesktopSection';
import { NamedLink } from 'components';
import { ConditionType } from 'config/configListing';
import { PIFERI_BRAND_ID } from 'containers/PiferiLandingPage/PiferiLandingPage';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { cn } from 'util/cn';
import { DEFAULT_SORTING } from 'util/constants';

const sampleSales = [
  {
    title: 'Piferi',
    imgSrc: 'https://cdn.thenold.com/images/2024-10-24_08-20-50__Sample - Piferi_compressed.webp',
    imgBlurSrc: 'https://cdn.thenold.com/images/2024-10-24_08-20-50__Sample - Piferi_blur.webp',
    to: `brandIds=${PIFERI_BRAND_ID}&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
  },
  {
    title: 'Pangaia',
    imgSrc: 'https://cdn.thenold.com/images/2024-10-24_08-20-50__Sample - Pangia_compressed.webp',
    imgBlurSrc: 'https://cdn.thenold.com/images/2024-10-24_08-20-50__Sample - Pangia_blur.webp',
    to: `brandIds=2885&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
  },
  {
    title: 'By Far',
    imgSrc: 'https://cdn.thenold.com/images/2024-10-24_08-20-50__Sample - By Far_compressed.webp',
    imgBlurSrc: 'https://cdn.thenold.com/images/2024-10-24_08-20-50__Sample - By Far_blur.webp',
    to: `brandIds=2854&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
  },
];

const NewSampleSaleSection = forwardRef<HTMLDivElement>((_, ref) => {
  const isDesktop = useMediaQueries({ viewport: 'small' });
  return isDesktop ? (
    <NewSampleSaleSectionDesktop ref={ref} />
  ) : (
    <NewSampleSaleSectionMobile ref={ref} />
  );
});

NewSampleSaleSection.displayName = 'NewSampleSaleSection';

export default NewSampleSaleSection;

const NewSampleSaleSectionDesktop = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <NewDesktopSection
      ref={ref}
      heading={
        <>
          <h3>
            <span className="text-1 bg-custom-green">Featuring</span>
          </h3>
          <h2>Sample sales </h2>
        </>
      }
    >
      {sampleSales.map(sampleSale => (
        <NamedLink
          key={sampleSale.title}
          name="SearchPage"
          to={{ search: sampleSale.to }}
          className="min-w-[220px] w-1/3 flex-shrink-0 relative"
        >
          <img src={sampleSale.imgSrc} alt={sampleSale.title} className="w-full object-cover" />
          <h4 className="absolute bottom-0 left-0 right-0 px-2 text-center tracking-wider pb-8 font-syne text-0 font-bold uppercase">
            {sampleSale.title}
          </h4>
        </NamedLink>
      ))}
    </NewDesktopSection>
  );
});

const NewSampleSaleSectionMobile = forwardRef<HTMLDivElement, { className?: string }>(
  ({ className }, ref) => {
    return (
      <motion.div
        className={cn('pt-5 pb-10 w-full grid gap-3', className)}
        {...fadeInProps}
        ref={ref}
      >
        <div className="flex flex-col gap-1 items-center">
          <h3 className="font-syne text-0 font-bold uppercase bg-custom-green">Featuring</h3>
          <h2 className="font-syne text-3 md:text-5 font-bold uppercase">Sample sales</h2>
        </div>
        <div className="flex">
          {sampleSales.map(sampleSale => (
            <NamedLink
              key={sampleSale.title}
              name="SearchPage"
              to={{
                search: sampleSale.to,
              }}
              className="relative grid flex-1 grid-rows-[1fr_auto]"
            >
              <img
                src={sampleSale.imgSrc}
                alt={sampleSale.title}
                className="w-full h-full object-contain"
              />
              <h4 className="absolute bottom-0 left-0 right-0 px-2 text-center tracking-wider pb-1 font-syne text-0 font-bold uppercase">
                {sampleSale.title}
              </h4>
            </NamedLink>
          ))}
        </div>
      </motion.div>
    );
  }
);
