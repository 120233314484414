import { ROOT_NODE, useEditor, useNode } from '@craftjs/core';
import * as Portal from '@radix-ui/react-portal';
import { ArrowUpIcon, MoveIcon, TrashIcon } from 'lucide-react';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

export const RenderNode = ({ render }: { render: ReactNode }) => {
  const { id } = useNode();
  const { actions, query, isActive, showGuidelines } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
    showGuidelines: (query.getOptions() as any)?.showGuidelines,
  }));

  const {
    isHover,
    dom,
    name,
    moveable,
    deletable,
    connectors: { drag },
    parent,
  } = useNode(node => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
  }));

  const currentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (dom) {
      if (isActive || isHover) dom.classList.add('component-selected');
      else dom.classList.remove('component-selected');
    }
  }, [dom, isActive, isHover]);

  const getPos = useCallback((dom: HTMLElement) => {
    const { top, left, bottom } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0 };
    return {
      top: `${top > 0 ? top : bottom}px`,
      left: `${left}px`,
    };
  }, []);

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;

    if (!currentDOM || !dom) return;
    const { top, left } = getPos(dom);
    currentDOM.style.top = top;
    currentDOM.style.left = left;
  }, [dom, getPos]);

  useEffect(() => {
    document?.querySelector('.craftjs-renderer')?.addEventListener('scroll', scroll);

    return () => {
      document?.querySelector('.craftjs-renderer')?.removeEventListener('scroll', scroll);
    };
  }, [scroll]);

  return (
    <>
      {(isHover || isActive) && showGuidelines ? (
        <Portal.Root>
          <div
            ref={currentRef}
            className="px-2 py-2 text-black shadow-lg border border-black bg-custom-green fixed -translate-y-full flex items-center gap-2"
            style={{
              left: dom ? getPos(dom).left : 0,
              top: dom ? getPos(dom).top : 0,
              zIndex: 9999,
            }}
          >
            <div>{name}</div>
            {moveable ? (
              <button className="cursor-move" ref={drag as any}>
                <MoveIcon size={16} />
              </button>
            ) : null}
            {id !== ROOT_NODE && (
              <button
                className="cursor-pointer"
                onClick={() => {
                  actions.selectNode(parent ?? '');
                }}
              >
                <ArrowUpIcon size={16} />
              </button>
            )}
            {deletable ? (
              <button
                className="cursor-pointer"
                onMouseDown={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  actions.delete(id);
                }}
              >
                <TrashIcon size={16} />
              </button>
            ) : null}
          </div>
          <div
            className="fixed pointer-events-none top-0 left-0 w-full h-full z-[9998] outline-dashed outline-2 outline-custom-green shadow-[0_0_0_2px_rgba(255,0,0,1)]"
            style={{
              left: dom ? getPos(dom).left : 0,
              top: dom ? getPos(dom).top : 0,
              width: dom ? dom.getBoundingClientRect().width : 0,
              height: dom ? dom.getBoundingClientRect().height : 0,
            }}
          />
        </Portal.Root>
      ) : null}
      {render}
    </>
  );
};
