import { useNode } from '@craftjs/core';
import { ComponentProps } from 'react';

import FollowingSection from 'containers/CustomLandingPage/components/FollowingSection/FollowingSection';

export const FollowingSectionWrapper = (props: ComponentProps<typeof FollowingSection>) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return <FollowingSection {...props} ref={ref => ref && connect(drag(ref))} />;
};

FollowingSectionWrapper.craft = {
  displayName: 'Following',
};

export default FollowingSectionWrapper;
