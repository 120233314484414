import { useNode } from '@craftjs/core';
import { ComponentProps } from 'react';

import NewSampleSaleSection from 'containers/CustomLandingPage/components/NewSampleSaleSection';

function SampleSaleSectionWrapper(props: ComponentProps<typeof NewSampleSaleSection>) {
  const {
    connectors: { connect, drag },
  } = useNode();
  return <NewSampleSaleSection {...props} ref={ref => ref && connect(drag(ref))} />;
}

SampleSaleSectionWrapper.craft = {
  displayName: 'Sample Sale',
};

export default SampleSaleSectionWrapper;
