import * as Popover from '@radix-ui/react-popover';
import { CopyIcon } from 'lucide-react';

import { cn } from 'util/cn';

interface CopyPopoverProps {
  onDuplicate: () => void;
  onCopyToClipboard: () => void;
  isRoot?: boolean;
}

export const CopyPopover = ({ onDuplicate, onCopyToClipboard, isRoot }: CopyPopoverProps) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button className="m-.25 p-.25">
          <CopyIcon className="h-2 w-2" />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="bg-white rounded-md shadow-md p-1 grid gap-1 z-50"
          sideOffset={5}
        >
          <button
            className={cn(
              'text-sm px-2 py-1 hover:bg-neutral-100 rounded text-left whitespace-nowrap',
              isRoot && 'opacity-30 pointer-events-none'
            )}
            onClick={onDuplicate}
            disabled={isRoot}
          >
            Duplicate here
          </button>
          <button
            className="text-sm px-2 py-1 hover:bg-neutral-100 rounded text-left whitespace-nowrap"
            onClick={onCopyToClipboard}
          >
            Copy to clipboard
          </button>
          <Popover.Arrow className="fill-white" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
