import { useNode } from '@craftjs/core';

import NewMatrixSection from 'containers/CustomLandingPage/components/NewMatrixSection';

export const MatrixSectionWrapper = () => {
  const {
    connectors: { connect, drag },
  } = useNode();

  const handleClickYes = () => {
    const currentElement = document.querySelector('[data-matrix-section="true"]');
    if (currentElement) {
      const nextElement = currentElement.nextElementSibling;
      if (nextElement) {
        nextElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <NewMatrixSection
      onClickYes={handleClickYes}
      ref={ref => {
        if (ref) {
          ref.setAttribute('data-matrix-section', 'true');
          connect(drag(ref));
        }
      }}
    />
  );
};

MatrixSectionWrapper.craft = {
  displayName: 'Matrix',
  props: {},
};
