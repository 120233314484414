import { useNode } from '@craftjs/core';
import { ComponentProps } from 'react';

import BlackSectionSettings from './BlackSectionSettings';
import NewBlackSection from 'containers/CustomLandingPage/components/NewBlackSection';

function NewBlackSectionWrapper(props: ComponentProps<typeof NewBlackSection>) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <NewBlackSection {...props} ref={ref => ref && connect(drag(ref))}>
      {props.children}
    </NewBlackSection>
  );
}

NewBlackSectionWrapper.craft = {
  displayName: 'Black',
  props: {
    sentences: ['Black Sentence 1', 'Black Sentence 2', 'Black Sentence 3', 'Black Sentence 4'],
    ctaText: 'Black CTA Text',
  },
  isCanvas: true,
  related: {
    settings: BlackSectionSettings,
  },
};

export default NewBlackSectionWrapper;
