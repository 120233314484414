import { useNode } from '@craftjs/core';
import { ComponentProps } from 'react';

import FooterContainer from 'containers/FooterContainer/FooterContainer';

function FooterSectionWrapper(props: ComponentProps<typeof FooterContainer>) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div ref={ref => ref && connect(drag(ref))}>
      <FooterContainer {...props} />
    </div>
  );
}

FooterSectionWrapper.craft = {
  displayName: 'Footer',
};

export default FooterSectionWrapper;
