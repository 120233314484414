import { HTMLMotionProps } from 'framer-motion';
import merge from 'lodash/merge';
import { ReactHTML } from 'react';

export const fadeInProps: HTMLMotionProps<'div'> = {
  initial: { opacity: 0, y: 10 },
  whileInView: { opacity: 1, y: 0 },
  viewport: { once: true, margin: '0px 0px -15% 0px' },
  transition: { delay: 0.15 },
};

export function mergeMotionProps<T extends keyof ReactHTML>(
  props: HTMLMotionProps<T>,
  ...rest: HTMLMotionProps<T>[]
) {
  return merge({}, props, ...rest) as HTMLMotionProps<T>;
}
