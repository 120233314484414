import { useNode } from '@craftjs/core';
import React from 'react';

import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';
import { RouteName } from 'routing/routeConfiguration';

function BlackSectionSettings() {
  const {
    actions: { setProp },
    sentences,
    ctaText,
    ctaLink,
  } = useNode(node => ({
    sentences: node.data.props.sentences,
    ctaText: node.data.props.ctaText,
    ctaLink: node.data.props.ctaLink,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div className="font-bold">Black Section Settings</div>

      <div className="flex flex-col gap-1">
        <label>Sentences</label>
        {sentences.map((sentence: string, index: number) => (
          <input
            key={index}
            type="text"
            value={sentence}
            onChange={e => setProp((props: any) => (props.sentences[index] = e.target.value))}
            className="border p-1"
          />
        ))}
        <button
          onClick={() => setProp((props: any) => props.sentences.push('New sentence'))}
          className="bg-green-200 p-1 mt-1"
        >
          Add Sentence
        </button>
        <button
          onClick={() => setProp((props: any) => props.sentences.pop())}
          className="bg-red-200 p-1"
        >
          Remove Last Sentence
        </button>
      </div>

      <label className="flex flex-col gap-1">
        CTA Text
        <input
          type="text"
          value={ctaText}
          onChange={e => setProp((props: any) => (props.ctaText = e.target.value))}
          className="border p-1"
        />
      </label>

      <div className="flex flex-col gap-2">
        <label className="flex items-center gap-2">
          <Checkbox
            checked={typeof ctaLink === 'string'}
            onCheckedChange={checked => {
              if (checked) {
                setProp((props: any) => (props.ctaLink = 'https://'));
              } else {
                setProp((props: any) => (props.ctaLink = { name: 'LandingPage', params: {} }));
              }
            }}
          />
          External URL
        </label>
        {typeof ctaLink === 'string' && (
          <input
            type="text"
            value={ctaLink}
            onChange={e => setProp((props: any) => (props.ctaLink = e.target.value))}
            className="border p-1"
            placeholder="https://"
          />
        )}

        {typeof ctaLink === 'object' && (
          <div className="grid gap-2">
            <select
              value={ctaLink.name}
              onChange={e =>
                setProp(
                  (props: any) =>
                    (props.ctaLink = { name: e.target.value as RouteName, params: ctaLink.params })
                )
              }
              className="border p-1"
            >
              <option value="LandingPage">Landing Page</option>
              <option value="SearchPage">Search Page</option>
            </select>
            <label className="flex flex-col gap-1">
              Route Params (JSON)
              <textarea
                value={JSON.stringify(ctaLink.params, null, 2)}
                onChange={e => {
                  try {
                    const params = JSON.parse(e.target.value);
                    setProp(
                      (props: any) => (props.ctaLink = { name: ctaLink.name, params: params })
                    );
                  } catch (err) {
                    // Invalid JSON, ignore
                  }
                }}
                className="border p-1 font-mono text-sm"
                placeholder="{}"
                rows={4}
              />
            </label>
          </div>
        )}
      </div>
    </div>
  );
}

export default BlackSectionSettings;
