import { motion } from 'framer-motion';
import { forwardRef, useState } from 'react';

import PostingCreateModal from '../../PostingsPage/PostingCreateModal';
import { fadeInProps } from '../animations';
import { NewDesktopSection } from './NewDesktopSection';
import { IconPlus } from 'assets/icons';
import { Button, NamedLink, PrimaryButton } from 'components';
import { PostingDetailsDrawer } from 'containers/PostingsPage/PostingDetailsDrawer';
import { PostingItem } from 'containers/PostingsPage/PostingItem';
import { PostingsGrid } from 'containers/PostingsPage/PostingsGrid';
import { useGetPostings } from 'containers/PostingsPage/PostingsPage.hooks';
import { useMediaQueries } from 'hooks/useMediaQueries';

const DISPLAY_LIMIT_MOBILE = 8;
const DISPLAY_LIMIT_DESKTOP = 16;

export const NewLookingForSection = forwardRef<HTMLDivElement, { className?: string }>(
  ({ className }, ref) => {
    const isDesktop = useMediaQueries({ viewport: 'small' });
    return isDesktop ? (
      <NewLookingForSectionDesktop ref={ref} className={className} />
    ) : (
      <NewLookingForSectionMobile ref={ref} className={className} />
    );
  }
);

const NewLookingForSectionDesktop = forwardRef<HTMLDivElement, { className?: string }>(
  ({ className }, ref) => {
    const { data: postingsData } = useGetPostings({
      pageSize: DISPLAY_LIMIT_DESKTOP,
    });
    const postings = postingsData?.pages.flatMap(page => page.postings);

    const [postingId, setPostingId] = useState<number | undefined>(undefined);

    return (
      <NewDesktopSection
        ref={ref}
        heading={
          <>
            People are
            <br />
            #lookingfor
            <PostingCreateModal>
              <Button>
                <IconPlus className="bg-custom-green text-black rounded-full" />
                #lookingfor
              </Button>
            </PostingCreateModal>
            <NamedLink name="PostingsPage">
              <PrimaryButton className="w-full">See all</PrimaryButton>
            </NamedLink>
          </>
        }
        className="h-[477px]"
      >
        {postings?.map(posting => (
          <div className="w-[300px] grid items-center border-l">
            <PostingItem
              key={posting.id}
              className="border-t-0 h-[475px]"
              posting={posting}
              onClickPosting={setPostingId}
              compact
            />
          </div>
        ))}
        <PostingDetailsDrawer postingId={postingId} onClose={() => setPostingId(undefined)} />
      </NewDesktopSection>
    );
  }
);

const NewLookingForSectionMobile = forwardRef<HTMLDivElement, { className?: string }>(
  ({ className }, ref) => {
    const [postingId, setPostingId] = useState<number | undefined>(undefined);

    return (
      <motion.div ref={ref} className="w-full md:hidden pb-5 pt-7" {...fadeInProps}>
        <h2 className="text-center uppercase font-syne mb-4 font-bold text-2">
          People are
          <br />
          #lookingfor
        </h2>
        <PostingsGrid displayLimit={DISPLAY_LIMIT_MOBILE} onClickPosting={setPostingId} />
        <PostingDetailsDrawer postingId={postingId} onClose={() => setPostingId(undefined)} />
        <PostingCreateModal>
          <div className="mt-5 flex justify-center">
            <PrimaryButton>
              <IconPlus className="bg-custom-green text-black rounded-full" />
              Add looking for
            </PrimaryButton>
          </div>
        </PostingCreateModal>
      </motion.div>
    );
  }
);

export default NewLookingForSection;
