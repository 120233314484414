import { useNode } from '@craftjs/core';
import { ComponentProps } from 'react';

import NewInstagramSection from 'containers/CustomLandingPage/components/NewInstagramSection';

function InstagramSectionWrapper(props: ComponentProps<typeof NewInstagramSection>) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return <NewInstagramSection {...props} ref={ref => ref && connect(drag(ref))} />;
}

InstagramSectionWrapper.craft = {
  displayName: 'Instagram',
};

export default InstagramSectionWrapper;
