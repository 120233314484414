import { useNode, UserComponent } from '@craftjs/core';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { ButtonSettings } from './ButtonSettings';
import { Button as ButtonComponent, PrimaryButton as PrimaryButtonComponent } from 'components';

type P = {
  variant?: 'default' | 'primary';
  linkPath?: string;
  text?: string;
  width?: 'fit' | 'full' | number;
};

export const Button: UserComponent<P> = ({
  variant,
  linkPath,
  text = 'Button',
  width = 'fit',
  ...props
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  const Comp = variant === 'primary' ? PrimaryButtonComponent : ButtonComponent;

  let WrapperComponent: React.ComponentType<PropsWithChildren<any>> = React.Fragment;
  if (linkPath) {
    const isExternal = linkPath.startsWith('http');

    WrapperComponent = ({ children }) => {
      if (isExternal) {
        return (
          <a
            href={linkPath}
            target="_blank"
            rel="noopener noreferrer"
            className={width === 'full' ? 'w-full' : 'w-fit'}
            ref={ref => ref && connect(drag(ref))}
          >
            {children}
          </a>
        );
      }

      return (
        <Link
          to={linkPath}
          className={width === 'full' ? 'w-full' : 'w-fit'}
          ref={ref => ref && connect(drag(ref))}
        >
          {children}
        </Link>
      );
    };
  }

  return (
    <WrapperComponent>
      <Comp
        {...props}
        className={width === 'full' ? 'w-full' : width === 'fit' ? 'w-fit' : ''}
        style={typeof width === 'number' ? { width: `${width}px` } : {}}
        ref={ref => !linkPath && ref && connect(drag(ref as any))}
      >
        {text}
      </Comp>
    </WrapperComponent>
  );
};

Button.craft = {
  displayName: 'Button',
  related: {
    settings: ButtonSettings,
  },
};
