import { useNode, UserComponent } from '@craftjs/core';
import * as Accordion from '@radix-ui/react-accordion';

import { AccordionSectionSettings } from './AccordionSectionSettings';
import { IconChevronDown } from 'assets/icons';
import { cn } from 'util/cn';

export type AccordionItem = {
  label: string;
  content: string;
};

type AccordionSectionProps = {
  title?: string;
  items: AccordionItem[];
  showTopBorder?: boolean;
};

export const AccordionSection: UserComponent<AccordionSectionProps> = ({
  title = 'Accordion Section',
  items = [],
  showTopBorder = false,
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      ref={ref => ref && connect(drag(ref))}
      className={cn(
        'w-full max-w-[var(--n-content-max-width)] mx-auto px-3 lg:px-10 py-5',
        showTopBorder && 'border-t'
      )}
    >
      {title && <h2 className="text-3 font-bold mb-4 font-syne">{title}</h2>}
      <Accordion.Root type="single" className="w-full grid gap-1" collapsible>
        {items.map((item, index) => (
          <Accordion.Item value={`item-${index}`} key={index} className="border-b last:border-b-0">
            <Accordion.Header>
              <Accordion.Trigger className="flex w-full items-center justify-between py-2 font-syne text-0 font-bold uppercase">
                <span>{item.label}</span>
                <IconChevronDown className="transition-transform duration-300 ui-state-open:rotate-180" />
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
              <div className="pb-2 whitespace-pre-wrap">{item.content}</div>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </div>
  );
};

AccordionSection.craft = {
  displayName: 'Accordion Section',
  props: {
    title: 'FAQ Section',
    items: [
      {
        label: 'Question 1',
        content: 'Answer to question 1',
      },
      {
        label: 'Question 2',
        content: 'Answer to question 2',
      },
    ],
  },
  related: {
    settings: AccordionSectionSettings,
  },
};
