import { animate, motion, MotionValue, useAnimationFrame, useMotionValue } from 'framer-motion';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { fadeInProps } from '../animations';
import { NewDesktopSection } from './NewDesktopSection';
import { ImageLazy, NamedLink, PrimaryButton } from 'components';
import { useGetConfiguration } from 'hooks/api/useGetConfiguration';
import { useMediaQueries } from 'hooks/useMediaQueries';

type P = {
  className?: string;
  title: string;
  subtitle: string;
  collectionId: number;
  buttonText?: string;
  buttonLinkType?: 'collection' | 'custom';
  buttonCustomPath?: string;
  items: Array<{
    collectionId?: number;
    link?: string;
    title?: string;
    imageUrl: string;
    imageBlurUrl: string;
    whiteText?: boolean;
  }>;
};

export const NewCarouselSection = forwardRef(
  (
    {
      title,
      subtitle,
      collectionId,
      items,
      className,
      buttonText,
      buttonLinkType,
      buttonCustomPath,
    }: P,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const isMobile = !useMediaQueries({ viewport: 'small' });

    return isMobile ? (
      <NewCarouselSectionMobile
        ref={ref}
        title={title}
        subtitle={subtitle}
        collectionId={collectionId}
        items={items}
        className={className}
        buttonText={buttonText}
        buttonLinkType={buttonLinkType}
        buttonCustomPath={buttonCustomPath}
      />
    ) : (
      <NewCarouselSectionDesktop
        ref={ref}
        title={title}
        subtitle={subtitle}
        collectionId={collectionId}
        items={items}
        className={className}
        buttonText={buttonText}
        buttonLinkType={buttonLinkType}
        buttonCustomPath={buttonCustomPath}
      />
    );
  }
);

const NewCarouselSectionDesktop = forwardRef(
  (
    {
      title,
      subtitle,
      collectionId,
      items,
      className,
      buttonText,
      buttonLinkType,
      buttonCustomPath,
    }: P,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { data } = useGetConfiguration();
    const collections = data?.collections || [];
    const collection = collections.find(c => c.id === collectionId);

    return (
      <NewDesktopSection
        ref={ref}
        heading={
          <>
            <h3>
              <span className="text-1 bg-custom-green">{subtitle}</span>
            </h3>
            <h2>{title} </h2>
            {buttonText &&
              (buttonLinkType === 'custom' ? (
                <Link to={buttonCustomPath || ''}>
                  <PrimaryButton className="!text-1">{buttonText}</PrimaryButton>
                </Link>
              ) : (
                <NamedLink name="SearchPage" to={{ search: collection?.filterLink || '' }}>
                  <PrimaryButton className="!text-1">{buttonText}</PrimaryButton>
                </NamedLink>
              ))}
          </>
        }
      >
        {items.map((item, i) =>
          item.link?.startsWith('?') ? (
            <NamedLink
              key={i}
              name="SearchPage"
              to={{ search: item.link }}
              className="font-syne uppercase w-[300px] flex-shrink-0 relative bg-[#F4F4F4]"
            >
              <ImageLazy
                src={item.imageUrl}
                blurSrc={item.imageBlurUrl}
                alt={item.title}
                className="w-full aspect-[3/4] object-cover"
              />
              <div
                className={`absolute bottom-0 left-0 right-0 font-syne font-bold text-center text-1 pb-6 px-2 ${
                  item.whiteText ? 'text-white' : 'text-black'
                }`}
              >
                <span>{item.title}</span>
              </div>
            </NamedLink>
          ) : (
            <Link
              key={i}
              to={item.link || ''}
              className="font-syne uppercase w-[300px] flex-shrink-0 relative bg-[#F4F4F4]"
            >
              <ImageLazy
                src={item.imageUrl}
                blurSrc={item.imageBlurUrl}
                alt={item.title}
                className="w-full aspect-[3/4] object-cover"
              />
              <div
                className={`absolute bottom-0 left-0 right-0 font-syne font-bold text-center text-1 pb-6 px-2 ${
                  item.whiteText ? 'text-white' : 'text-black'
                }`}
              >
                <span>{item.title}</span>
              </div>
            </Link>
          )
        )}
      </NewDesktopSection>
    );
  }
);

const NewCarouselSectionMobile = forwardRef(
  (
    { title, subtitle, collectionId, items, buttonText, buttonLinkType, buttonCustomPath }: P,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { data } = useGetConfiguration();
    const collections = data?.collections || [];
    const collection = collections.find(c => c.id === collectionId);

    const x = useMotionValue(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const constraintsRef = useRef({ left: 0, right: 0 });

    const { pauseAutoScroll } = useAutoScroll(x, containerRef);

    // Update drag constraints when the component mounts and when the window resizes
    useEffect(() => {
      const updateConstraints = () => {
        if (containerRef.current) {
          const maxScrollWidth = getMaxScrollWidth(containerRef);
          constraintsRef.current = { left: -maxScrollWidth, right: 0 };
        }
      };

      updateConstraints();

      window.addEventListener('resize', updateConstraints);

      return () => {
        window.removeEventListener('resize', updateConstraints);
      };
    }, []);

    return (
      <motion.div ref={ref} className="py-5 w-full font-syne uppercase font-bold" {...fadeInProps}>
        <div className="flex flex-col gap-1 px-2 py-2">
          {subtitle && (
            <h3>
              <span className="text-00 bg-custom-green">{subtitle}</span>
            </h3>
          )}
          {title && (
            <h2 className="text-2">
              {title}{' '}
              {buttonText &&
                (buttonLinkType === 'custom' ? (
                  <Link
                    to={buttonCustomPath || ''}
                    className="underline underline-offset-4 ml-2 text-00"
                  >
                    {buttonText}
                  </Link>
                ) : (
                  <NamedLink
                    name="SearchPage"
                    to={{ search: collection?.filterLink || '' }}
                    className="underline underline-offset-4 ml-2 text-00"
                  >
                    {buttonText}
                  </NamedLink>
                ))}
            </h2>
          )}
        </div>
        <motion.div
          ref={containerRef}
          className="flex w-full cursor-grab"
          drag="x"
          dragConstraints={constraintsRef.current}
          dragElastic={0.5}
          dragTransition={{ timeConstant: 200, power: 0.5 }}
          style={{ x }}
          onDragStart={pauseAutoScroll}
        >
          {items.map((item, i) => {
            const itemCollection = collections.find(c => c.id === item.collectionId);
            const itemTitle = item.title || itemCollection?.name || '';

            const children = (
              <>
                <img
                  src={item.imageUrl}
                  className="overflow-hidden object-cover px-2 w-full"
                  alt={itemTitle}
                />
                <div
                  className={`absolute bottom-0 left-0 right-0 font-syne font-bold text-center text-00 pb-4 px-2 ${
                    item.whiteText ? 'text-white' : 'text-black'
                  }`}
                >
                  <span>{itemTitle}</span>
                </div>
              </>
            );

            return item.link?.startsWith('?') ? (
              <NamedLink
                key={i}
                name="SearchPage"
                to={{ search: item.link }}
                className="relative bg-[#F4F4F4] w-[200px] flex-shrink-0"
              >
                {children}
              </NamedLink>
            ) : (
              <Link
                key={i}
                to={item.link || ''}
                className="relative bg-[#F4F4F4] w-[200px] flex-shrink-0"
              >
                {children}
              </Link>
            );
          })}
        </motion.div>
      </motion.div>
    );
  }
);

export const useAutoScroll = (
  x: MotionValue<number>,
  containerRef: React.RefObject<HTMLDivElement>,
  { scrollSpeed = 50 } = {}
) => {
  const prevTimestamp = useRef(0);
  const [isScrolling, setIsScrolling] = useState(true);
  const isAnimatingToBeginning = useRef(false);

  useAnimationFrame(timestamp => {
    const msPerFrame = timestamp - prevTimestamp.current;
    prevTimestamp.current = timestamp;

    const delta = (scrollSpeed * msPerFrame) / 1000;

    const maxScrollWidth = getMaxScrollWidth(containerRef);
    const newX = Math.max(x.get() - delta, -maxScrollWidth);
    if (newX !== x.get() && isScrolling) {
      x.set(newX);
    }

    const isAtEnd = x.get() <= -maxScrollWidth;
    if (isAtEnd && isScrolling && !isAnimatingToBeginning.current) {
      isAnimatingToBeginning.current = true;
      setTimeout(() => {
        animate(x, 0, {
          duration: 0.5,
          onComplete: () => {
            isAnimatingToBeginning.current = false;
          },
        });
      }, 3000);
    }

    if (x.getVelocity() === 0 && !isScrolling && !isAtEnd) {
      setIsScrolling(true);
    }
  });

  const pauseAutoScroll = useCallback(() => {
    setIsScrolling(false);
  }, []);

  return { pauseAutoScroll };
};

export const getMaxScrollWidth = (containerRef: React.RefObject<HTMLDivElement>) => {
  if (containerRef.current) {
    const scrollWidth = containerRef.current.scrollWidth;
    const clientWidth = containerRef.current.clientWidth;
    return scrollWidth - clientWidth;
  }
  return 0;
};
