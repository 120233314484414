import { useNode } from '@craftjs/core';

import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';

export const ImageSettings = () => {
  const {
    actions: { setProp },
    src,
    blurSrc,
    alt,
    width,
    height,
    objectFit,
    objectPosition,
    responsiveMinScreen,
    responsiveMaxScreen,
  } = useNode(node => ({
    src: node.data.props.src,
    blurSrc: node.data.props.blurSrc,
    alt: node.data.props.alt,
    width: node.data.props.width,
    height: node.data.props.height,
    objectFit: node.data.props.objectFit,
    objectPosition: node.data.props.objectPosition,
    responsiveMinScreen: node.data.props.responsiveMinScreen,
    responsiveMaxScreen: node.data.props.responsiveMaxScreen,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Image Settings</div>

      <label>
        Image URL
        <input
          type="text"
          value={src}
          onChange={e => setProp((props: any) => (props.src = e.target.value))}
          placeholder="Enter image URL"
          className="w-full"
        />
      </label>

      <label>
        Blur Image URL
        <input
          type="text"
          value={blurSrc}
          onChange={e => setProp((props: any) => (props.blurSrc = e.target.value))}
          placeholder="Enter blur image URL"
          className="w-full"
        />
      </label>

      <label>
        Alt Text
        <input
          type="text"
          value={alt}
          onChange={e => setProp((props: any) => (props.alt = e.target.value))}
          placeholder="Enter alt text"
          className="w-full"
        />
      </label>

      <label className="grid gap-.5">
        Width
        <select
          value={width === 'full' ? 'full' : 'custom'}
          onChange={e => {
            const value = e.target.value;
            if (value === 'full') {
              setProp((props: any) => (props.width = value));
            } else if (value === 'custom') {
              setProp((props: any) => (props.width = 200));
            }
          }}
          className="bg-white"
        >
          <option value="full">Full width</option>
          <option value="custom">Custom width</option>
        </select>
        {width !== 'full' && (
          <input
            type="number"
            value={width}
            onChange={e => setProp((props: any) => (props.width = Number(e.target.value)))}
            placeholder="Width in pixels"
            className="bg-white mt-1"
          />
        )}
      </label>

      <label>
        Height
        <input
          type="number"
          value={height || ''}
          onChange={e => setProp((props: any) => (props.height = Number(e.target.value)))}
          placeholder="Height in pixels"
          className="w-full"
        />
      </label>

      <label>
        Object Fit
        <select
          value={objectFit}
          onChange={e => setProp((props: any) => (props.objectFit = e.target.value))}
          className="w-full"
        >
          <option value="contain">Contain</option>
          <option value="cover">Cover</option>
          <option value="fill">Fill</option>
          <option value="none">None</option>
          <option value="scale-down">Scale Down</option>
        </select>
      </label>

      <label>
        Object Position
        <input
          type="text"
          value={objectPosition}
          onChange={e => setProp((props: any) => (props.objectPosition = e.target.value))}
          placeholder="e.g., center, top right, 50% 50%"
          className="w-full"
        />
      </label>

      <label>
        Responsive Min Screen
        <input
          type="text"
          value={responsiveMinScreen || ''}
          onChange={e => setProp((props: any) => (props.responsiveMinScreen = e.target.value))}
          placeholder="0px (default)"
        />
        <small className="max-w-[300px] inline-block mt-1">
          Enter a pixel value (e.g., 768px) or a breakpoint name (xs, sm, md, lg, xl). The component
          will be hidden below this screen size.
        </small>
      </label>

      <label>
        Responsive Max Screen
        <input
          type="text"
          value={responsiveMaxScreen || ''}
          onChange={e => setProp((props: any) => (props.responsiveMaxScreen = e.target.value))}
          placeholder="Infinity (default)"
        />
        <small className="max-w-[300px] inline-block mt-1">
          Enter a pixel value (e.g., 1024px) or a breakpoint name (xs, sm, md, lg, xl). The
          component will be hidden above this screen size.
        </small>
      </label>
    </div>
  );
};
