import { useEditor } from '@craftjs/core';
import { EditableLayerName, useLayer } from '@craftjs/layers';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CornerUpLeftIcon,
  EyeIcon,
  EyeOffIcon,
  MonitorSmartphoneIcon,
  TrashIcon,
} from 'lucide-react';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import shortid from 'shortid';

import { CopyPopover } from './components/CopyPopover';
import { Text } from './components/Text';
import { useGetCloneTree } from './EditablePages.hooks';
import { cn } from 'util/cn';

export function Layer({ children }) {
  const layer = useLayer(layer => {
    return {
      hovered: layer.event.hovered,
      expanded: layer.expanded,
    };
  });
  const { actions, query, selected, hidden } = useEditor((state, query) => {
    const parentNode = Object.values(state.nodes).find(node => node.data.nodes?.includes(layer.id));
    const layerChildIndex = parentNode?.data.nodes?.indexOf(layer.id);
    const selected = state.events.selected;
    const hidden = state.nodes[layer.id]?.data.hidden;

    return {
      selected,
      hidden,
      layerChildIndex,
      hasChildCanvases: query.node(layer.id).isParentOfTopLevelNodes(),
    };
  });

  const getCloneTree = useGetCloneTree();

  const handleClone = useCallback(
    (id: string) => {
      const theNode = query.node(id).get();
      const parentNode = query.node(theNode.data.parent || '').get();
      const indexToAdd = parentNode.data.nodes.indexOf(id);
      const tree = getCloneTree(id);
      actions.addNodeTree(tree, parentNode.id, indexToAdd + 1);

      setTimeout(() => {
        actions.deserialize(query.serialize());
        actions.selectNode(tree.rootNodeId);
      }, 100);
    },
    [actions, getCloneTree, query]
  );

  const moveOutOfParent = useCallback(() => {
    const node = query.node(layer.id);
    const parent = node.get().data.parent;

    if (!parent) return;

    const parentNode = query.node(parent);
    const grandParent = parentNode.get().data.parent;
    if (grandParent) {
      const parentIndex = query.node(grandParent).get().data.nodes.indexOf(parent);
      actions.move(layer.id, grandParent, parentIndex + 1);
    }
  }, [actions, layer.id, query]);

  const move = useCallback(
    ({ up }: { up: boolean }) => {
      const node = query.node(layer.id);
      const parent = node.get().data.parent;

      if (!parent) return;

      const parentNode = query.node(parent);
      const siblings = parentNode.get().data.nodes || [];
      const currentIndex = siblings.indexOf(layer.id);

      if (up && currentIndex > 0) {
        actions.move(layer.id, parent, currentIndex - 1);
      } else if (!up && currentIndex < siblings.length - 1) {
        actions.move(layer.id, parent, currentIndex + 2);
      }
    },
    [actions, layer.id, query]
  );

  const isRoot = layer.id === 'ROOT';
  const node = query.node(layer.id).get();
  const isResponsive =
    node.data.type === Text &&
    (node.data.props.responsiveMinScreen || node.data.props.responsiveMaxScreen);

  return (
    <div ref={ref => ref && layer.connectors.layer(ref)}>
      <div
        className={cn('flex items-center', selected?.has(layer.id) && 'bg-yellow-200')}
        ref={ref => ref && layer.connectors.drag(ref)}
      >
        <button
          className={cn('m-.25 p-.25', isRoot && 'opacity-30')}
          disabled={isRoot}
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this layer?')) {
              actions.delete(layer.id);
            }
          }}
        >
          <TrashIcon className="h-2 w-2" />
        </button>
        <CopyPopover
          isRoot={isRoot}
          onDuplicate={() => handleClone(layer.id)}
          onCopyToClipboard={() => {
            const nodeTree = JSON.parse(query.serialize());

            function getNodeIds(id: string, durvence: any) {
              const node = durvence[id];
              return [id, ...node.nodes.flatMap(childId => getNodeIds(childId, durvence))];
            }

            const nodeIds = getNodeIds(layer.id, nodeTree);
            const newIds = new Map(
              nodeIds.map(id => {
                return [id, id === 'ROOT' ? id : shortid()];
              })
            );

            const onlyRelevantNodes = Object.entries(nodeTree).reduce((acc, [id, node]: any) => {
              const newId = newIds.get(id);
              console.log('newId', id, newId);
              if (newId) {
                return {
                  ...acc,
                  [newId]: {
                    ...node,
                    id: newId,
                    parent: newIds.get(node.parent),
                    nodes: node.nodes.map(childId => newIds.get(childId)),
                  },
                };
              }
              return acc;
            }, {});

            const serializedData = JSON.stringify({
              isCraftjsLayer: true,
              data: onlyRelevantNodes,
              rootNodeId: newIds.get(layer.id),
            });

            navigator.clipboard
              .writeText(serializedData)
              .then(() => toast.success('Layer and its children copied to clipboard'))
              .catch(() => toast.error('Failed to copy layer'));
          }}
        />
        <button
          className={cn('my-.25 p-.25', isRoot && 'opacity-30')}
          disabled={isRoot}
          onClick={moveOutOfParent}
        >
          <CornerUpLeftIcon className="h-2 w-2" />
        </button>
        <button
          className={cn('my-.25 p-.25', isRoot && 'opacity-30')}
          disabled={isRoot}
          onClick={() => move({ up: true })}
        >
          <ArrowUpIcon className="h-2 w-2" />
        </button>
        <button
          className={cn('my-.25 p-.25', isRoot && 'opacity-30')}
          disabled={isRoot}
          onClick={() => move({ up: false })}
        >
          <ArrowDownIcon className="h-2 w-2" />
        </button>
        <button
          className={cn('m-.25 p-.25', isRoot && 'opacity-30')}
          disabled={isRoot}
          onClick={() => {
            actions.setHidden(layer.id, !hidden);
          }}
        >
          {hidden ? <EyeOffIcon className="h-2 w-2" /> : <EyeIcon className="h-2 w-2" />}
        </button>
        {new Array(layer.depth).fill(0).map((_, index) => (
          <div key={index} className="w-1 border-l" />
        ))}
        <div
          className="flex-1 flex gap-1 items-center"
          ref={ref => ref && layer.connectors.layerHeader(ref)}
        >
          <EditableLayerName />
          {isResponsive && (
            <MonitorSmartphoneIcon className="h-2 w-2 p-.25 rounded-sm text-white bg-red-500" />
          )}
        </div>
        {Boolean(layer.children?.length) && (
          <button onClick={layer.actions.toggleLayer}>{layer.expanded ? '▼' : '▶'}</button>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
}
