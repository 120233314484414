import { useNode } from '@craftjs/core';

export const ButtonSettings = () => {
  const {
    actions: { setProp },
    variant,
    text,
    linkPath,
    width,
  } = useNode(node => ({
    variant: node.data.props.variant,
    text: node.data.props.text,
    linkPath: node.data.props.linkPath,
    width: node.data.props.width,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Button settings</div>

      <select
        value={variant}
        onChange={e => setProp((props: any) => (props.variant = e.target.value))}
      >
        <option value="default">Default</option>
        <option value="primary">Primary</option>
      </select>

      <div>
        Text
        <input
          type="text"
          value={text}
          onChange={e => setProp((props: any) => (props.text = e.target.value))}
          placeholder="Button text"
        />
      </div>

      <div className="grid gap-.5">
        <strong>Path (or external link)</strong>
        <input
          type="text"
          value={linkPath || ''}
          onChange={e => setProp((props: any) => (props.linkPath = e.target.value))}
          placeholder="e.g. /shop or https://www.google.com"
          className="bg-white"
        />
      </div>

      <div className="grid gap-.5">
        <strong>Width</strong>
        <select
          value={width === 'full' || width === 'fit' ? width : 'custom'}
          onChange={e => {
            const value = e.target.value;
            if (value === 'full' || value === 'fit') {
              setProp((props: any) => (props.width = value));
            } else if (value === 'custom') {
              setProp((props: any) => (props.width = 200)); // Default custom width
            }
          }}
          className="bg-white"
        >
          <option value="fit">Fit content</option>
          <option value="full">Full width</option>
          <option value="custom">Custom width</option>
        </select>
        {width !== 'full' && width !== 'fit' && (
          <input
            type="number"
            value={width}
            onChange={e => setProp((props: any) => (props.width = Number(e.target.value)))}
            placeholder="Custom width in pixels"
            className="bg-white mt-1"
          />
        )}
      </div>
    </div>
  );
};
