import css from './HeroCollectionSection.module.css';
import { Button, ImageLazy, NamedLink } from 'components';
import { ConditionType } from 'config/configListing';
import { I } from 'containers/PiferiLandingPage/PiferiCustomI';
import { PIFERI_BRAND_ID } from 'containers/PiferiLandingPage/PiferiLandingPage';
import { DEFAULT_SORTING } from 'util/constants';

const HeroCollectionSection = () => {
  const imgProps = {
    src: 'https://cdn.thenold.com/landing-pages/piferi/MIRAGE 100 FLASH TECHNO IRIS BLUE_2_compressed.webp',
    blurSrc:
      'https://cdn.thenold.com/landing-pages/piferi/MIRAGE 100 FLASH TECHNO IRIS BLUE_2_compressed.webp',
  };

  return (
    <div className={css.root}>
      <ImageLazy {...imgProps} alt="Piferi" />
      <div className={css.container}>
        <h1>
          P<I />
          feri Circular
        </h1>
        <span style={{ display: 'flex', gap: 'var(--n-size-2)' }}>
          <NamedLink
            name="SearchPage"
            to={{
              search: `?brandIds=${PIFERI_BRAND_ID}&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
            }}
          >
            <Button>Re-Shop</Button>
          </NamedLink>
          <NamedLink name="ResellLandingPage">
            <Button>Re-Sell</Button>
          </NamedLink>
        </span>
      </div>
    </div>
  );
};

export default HeroCollectionSection;
