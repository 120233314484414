import { useNode } from '@craftjs/core';

export const TimelineSectionSettings = () => {
  const {
    actions: { setProp },
    heading,
    items,
  } = useNode(node => ({
    heading: node.data.props.heading,
    items: node.data.props.items,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Timeline Section Settings</div>

      <label>
        Heading
        <input
          type="text"
          value={heading}
          onChange={e => setProp((props: any) => (props.heading = e.target.value))}
          className="w-full p-1"
        />
      </label>

      <div>
        <div className="font-bold mb-2">Timeline Items</div>
        {items.map((item: any, index: number) => (
          <div key={index} className="mb-4 p-2 border border-gray-200">
            <label>
              Main Photo URL
              <input
                type="text"
                value={item.mainPhotoSrc}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].mainPhotoSrc = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <label>
              Main Photo Blur URL
              <input
                type="text"
                value={item.mainPhotoBlur}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].mainPhotoBlur = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <label>
              Secondary Photo URL
              <input
                type="text"
                value={item.secondaryPhotoSrc}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].secondaryPhotoSrc = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <label>
              Secondary Photo Blur URL
              <input
                type="text"
                value={item.secondaryPhotoBlur}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].secondaryPhotoBlur = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <label>
              Title
              <input
                type="text"
                value={item.title}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].title = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <label>
              Description
              <textarea
                value={item.description}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].description = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <div className="flex gap-2 mt-2">
              <button
                onClick={() =>
                  setProp((props: any) => {
                    if (index > 0) {
                      const newItems = [...props.items];
                      [newItems[index - 1], newItems[index]] = [
                        newItems[index],
                        newItems[index - 1],
                      ];
                      props.items = newItems;
                    }
                  })
                }
                disabled={index === 0}
                className="bg-blue-500 text-white px-2 py-1 rounded disabled:opacity-50"
              >
                ↑ Move Up
              </button>
              <button
                onClick={() =>
                  setProp((props: any) => {
                    if (index < props.items.length - 1) {
                      const newItems = [...props.items];
                      [newItems[index], newItems[index + 1]] = [
                        newItems[index + 1],
                        newItems[index],
                      ];
                      props.items = newItems;
                    }
                  })
                }
                disabled={index === items.length - 1}
                className="bg-blue-500 text-white px-2 py-1 rounded disabled:opacity-50"
              >
                ↓ Move Down
              </button>
              <button
                onClick={() =>
                  setProp((props: any) => {
                    props.items = props.items.filter((_: any, i: number) => i !== index);
                  })
                }
                className="bg-red-500 text-white px-2 py-1 rounded"
              >
                Remove Item
              </button>
            </div>
          </div>
        ))}
        <button
          onClick={() =>
            setProp((props: any) => {
              props.items = [
                ...props.items,
                {
                  mainPhotoSrc: '',
                  mainPhotoBlur: '',
                  secondaryPhotoSrc: '',
                  secondaryPhotoBlur: '',
                  title: '',
                  description: '',
                },
              ];
            })
          }
          className="bg-green-500 text-white px-2 py-1 rounded"
        >
          Add Item
        </button>
      </div>
    </div>
  );
};
