import { Element, Node, useEditor } from '@craftjs/core';
import { Layers } from '@craftjs/layers';
import * as Accordion from '@radix-ui/react-accordion';
import { useAtom } from 'jotai';
import { atom } from 'jotai';
import { decode, encode } from 'js-base64';
import { PanelRightCloseIcon, PanelRightOpenIcon } from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';

import { AccordionSection } from './components/AccordionSection';
import { Button } from './components/Button';
import { Container } from './components/Container';
import { HeroSection } from './components/HeroSection';
import { Image } from './components/Image';
import { LeonieHero } from './components/LeonieHero';
import { ListingsSection } from './components/ListingsSection';
import { ShowcaseCarousel } from './components/ShowcaseCarousel';
import { ShowcaseCarouselItem } from './components/ShowcaseCarouselItem';
import { Spacer } from './components/Spacer';
import { Text } from './components/Text';
import TimelineSection, { DEFAULT_TIMELINE_ITEMS } from './components/TimelineSection';
import {
  EditablePageData,
  useEditablePagesQueryParams,
  useGetEditablePageVersions,
  useSetActiveVersion,
  useUpdateEditablePages,
} from './EditablePages.hooks';
import { Layer } from './Layer';
import BlackSectionWrapper from './wrappers/BlackSectionWrapper';
import CarouselSectionWrapper from './wrappers/CarouselSectionWrapper';
import FeaturedSectionWrapper from './wrappers/FeaturedSectionWrapper';
import { FollowingSectionWrapper } from './wrappers/FollowingSectionWrapper';
import FooterSectionWrapper from './wrappers/FooterSectionWrapper';
import IconsSectionWrapper from './wrappers/IconsSectionWrapper';
import InstagramSectionWrapper from './wrappers/InstagramSectionWrapper';
import ListingSectionWrapper from './wrappers/ListingSectionWrapper';
import LookingForSectionWrapper from './wrappers/LookingForSectionWrapper';
import { MatrixSectionWrapper } from './wrappers/MatrixSectionWrapper';
import SampleSaleSectionWrapper from './wrappers/SampleSaleSectionWrapper';
import TrendingNowSectionWrapper from './wrappers/TrendingNowSectionWrapper';
import { IconChevronDown } from 'assets/icons';
import { Button as SecondaryButton, NamedLink, PrimaryButton } from 'components';
import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';
import { cn } from 'util/cn';

const enableLinksAtom = atom(false);
export const useEnableLinksState = () => useAtom(enableLinksAtom);

export const Toolbox = ({ page }: { page: EditablePageData }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [enableLinks, setEnableLinks] = useEnableLinksState();
  const [showGuidelines, setShowGuidelines] = React.useState(true);

  const { mutateAsync: updateEditablePages } = useUpdateEditablePages();
  const { data: versionsData } = useGetEditablePageVersions();
  const [{ version }, setQueryParams] = useEditablePagesQueryParams();
  const path = page.path;
  const pageVersionData = versionsData?.find(v => v.path === path);
  const { mutateAsync: setActiveVersion } = useSetActiveVersion();

  const { connectors, actions, selected, query } = useEditor(state => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes?.[currentNodeId]?.related?.settings,
      };
    }

    return { selected };
  });

  useEffect(() => {
    actions.setOptions(options => {
      (options as any).showGuidelines = showGuidelines;
    });
  }, [showGuidelines, actions]);

  const pageContentRef = useRef('');
  useEffect(() => {
    if (pageContentRef.current !== page?.content) {
      pageContentRef.current = page?.content;
      if (pageContentRef.current) {
        actions.deserialize(decode(pageContentRef.current));
      }
    }
  }, [actions, page, page?.content]);
  const hasContentChanged = encode(query.serialize()) !== page?.content;

  return (
    <div className={cn(`relative bg-neutral-100`, isExpanded ? 'min-w-[350px]' : 'w-0')}>
      <button
        className="absolute -left-6 top-1/2 -translate-y-1/2 text-white bg-black p-1 border-2 border-white rounded-l-md shadow-md"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <PanelRightCloseIcon /> : <PanelRightOpenIcon />}
      </button>
      {isExpanded && (
        <div className="overflow-auto max-h-screen grid gap-2 content-start px-2 py-1">
          <NamedLink name="EditablePages" params={{ pageId: 'list' }}>
            <SecondaryButton className="w-full">Back to list</SecondaryButton>
          </NamedLink>
          <PrimaryButton
            onClick={() => {
              const editorState = query.serialize();
              updateEditablePages({
                path: page.path,
                content: encode(editorState),
              }).then(() => {
                setQueryParams({ version: undefined, edit: true });
              });
            }}
          >
            Save Page
          </PrimaryButton>
          <div className="grid gap-1">
            <Checkbox
              label={
                <strong>
                  Enable links (you will lose any unsaved <br />
                  changes if you click on a link)
                </strong>
              }
              checked={enableLinks}
              onCheckedChange={value => setEnableLinks(Boolean(value))}
            />
            <Checkbox
              label={<strong>Show layer guidelines</strong>}
              checked={showGuidelines}
              onCheckedChange={value => setShowGuidelines(Boolean(value))}
            />
          </div>
          <Accordion.Root type="multiple" defaultValue={['layers']} className="w-full grid gap-1">
            <Accordion.Item value="versions" className="bg-orange-50 p-1 grid gap-1">
              <Accordion.Header>
                <Accordion.Trigger className="flex gap-2 justify-between w-full">
                  <span>
                    Versions (displayed:{' '}
                    <strong className="bg-red-700 text-white px-.5">
                      {version || pageVersionData?.activeVersion || '...'}
                    </strong>
                    )
                  </span>
                  <IconChevronDown />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="grid gap-.5">
                  {pageVersionData?.versions?.map(v => {
                    return (
                      <button
                        key={v}
                        className="px-1 py-.25 bg-orange-100 w-full text-left flex items-center gap-1 justify-end"
                      >
                        <span className="flex items-center gap-1 mr-auto">
                          Version: {v}
                          {v === pageVersionData?.activeVersion && (
                            <span className="text-000 inline-block font-black bg-red-700 text-white px-.5">
                              PROD
                            </span>
                          )}
                        </span>
                        <button
                          className="bg-neutral-200 px-.5 py-.25 disabled:opacity-50 rounded-md text-00 inline-block"
                          disabled={!hasContentChanged}
                          onClick={() => {
                            if (
                              window.confirm(
                                'You have unsaved changes. Are you sure you want to switch versions? Your changes will be lost.'
                              )
                            ) {
                              setQueryParams({ version: v, edit: true });
                            }
                          }}
                        >
                          Load
                        </button>
                        <button
                          className="bg-neutral-200 px-.5 py-.25 disabled:opacity-50 rounded-md text-00 inline-block"
                          disabled={v === pageVersionData?.activeVersion}
                          onClick={e => {
                            e.stopPropagation();
                            if (v !== pageVersionData?.activeVersion) {
                              window.confirm(
                                'Are you sure you want to set this version as active?'
                              ) && setActiveVersion({ path, version: v });
                            }
                          }}
                        >
                          Set Active
                        </button>
                      </button>
                    );
                  })}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="components" className="bg-green-50 p-1 grid gap-1">
              <Accordion.Header>
                <Accordion.Trigger className="flex gap-2 justify-between w-full">
                  <span>Components</span>
                  <IconChevronDown />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="bg-green-100 grid gap-.5 p-.5 grid-cols-2">
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={HeroSection}
                          imgSrc="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_compressed.webp"
                          imgBlurSrc="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_blur.webp"
                          canvas
                        />
                      )
                    }
                  >
                    Hero Section
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={Button} text="Button" />)
                    }
                  >
                    Button
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={Text}>Text</Element>)}
                  >
                    Text
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={Image}
                          src="https://picsum.photos/800/600"
                          blurSrc="https://picsum.photos/20/15"
                          alt="Example image"
                        />
                      )
                    }
                  >
                    Image
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={Spacer} size="1" />)}
                  >
                    Spacer
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element is={Container} canvas>
                          <Element is={Text}>Container</Element>
                        </Element>
                      )
                    }
                  >
                    Container
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element is={ShowcaseCarousel} canvas>
                          <Element
                            is={ShowcaseCarouselItem}
                            imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          >
                            <Element
                              is={Text}
                              font="syne"
                              size="3"
                              casing="uppercase"
                              color="white"
                            >
                              Showcase Item 1
                            </Element>
                            <Element is={Spacer} size="2" />
                            <Element is={Button} text="Button 1" />
                          </Element>
                          <Element
                            is={ShowcaseCarouselItem}
                            imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          >
                            <Element
                              is={Text}
                              font="syne"
                              size="3"
                              casing="uppercase"
                              color="white"
                            >
                              Showcase Item 2
                            </Element>
                            <Element is={Spacer} size="2" />
                            <Element is={Button} text="Button 2" />
                          </Element>
                          <Element
                            is={ShowcaseCarouselItem}
                            imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          >
                            <Element
                              is={Text}
                              font="syne"
                              size="3"
                              casing="uppercase"
                              color="white"
                            >
                              Showcase Item 3
                            </Element>
                            <Element is={Spacer} size="2" />
                            <Element is={Button} text="Button 3" />
                          </Element>
                        </Element>
                      )
                    }
                  >
                    Showcase
                    <br />
                    Carousel
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={ShowcaseCarouselItem}
                          imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        >
                          <Element is={Text} font="syne" size="3" casing="uppercase" color="white">
                            Showcase Item
                          </Element>
                          <Element is={Spacer} size="2" />
                          <Element is={Button} text="Button 3" />
                        </Element>
                      )
                    }
                  >
                    Showcase
                    <br />
                    Carousel Item
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={ListingsSection} />)}
                  >
                    Listings
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={FollowingSectionWrapper} />)
                    }
                  >
                    🆕 Following
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={MatrixSectionWrapper} />)
                    }
                  >
                    🆕 Matrix
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={CarouselSectionWrapper}
                          title="Carousel Title"
                          subtitle="Carousel Subtitle"
                          collectionId={1}
                          items={[
                            ...new Array(6).fill(null).map((_, index) => ({
                              imageUrl: `https://picsum.photos/600/800?random=${index + 1}`,
                              imageBlurUrl: `https://picsum.photos/30/40?random=${index + 1}`,
                              title: `Item Title ${index + 1}`,
                              searchParams: '',
                            })),
                          ]}
                        />
                      )
                    }
                  >
                    🆕 Carousel
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={LookingForSectionWrapper} />)
                    }
                  >
                    🆕 Looking For
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={InstagramSectionWrapper} />)
                    }
                  >
                    🆕 Instagram
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={FeaturedSectionWrapper} />)
                    }
                  >
                    🆕 Featured in
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={BlackSectionWrapper}
                          canvas
                          sentences={[
                            'Black Sentence 1',
                            'Black Sentence 2',
                            'Black Sentence 3',
                            'Black Sentence 4',
                          ]}
                          ctaText="Black CTA Text"
                          ctaLink={{ name: 'LandingPage', params: {} }}
                        >
                          <Text>Black Title</Text>
                        </Element>
                      )
                    }
                  >
                    🆕 Black
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={SampleSaleSectionWrapper} />)
                    }
                  >
                    🆕 Sample Sale
                  </button>
                  {/* Icons */}
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={IconsSectionWrapper} />)}
                  >
                    🆕 Icons
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={TrendingNowSectionWrapper} />)
                    }
                  >
                    🆕 Trending Now
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={ListingSectionWrapper} />)
                    }
                  >
                    🆕 Listings Section
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={AccordionSection}
                          items={[
                            {
                              label: 'Question 1',
                              content: 'Answer to question 1',
                            },
                            {
                              label: 'Question 2',
                              content: 'Answer to question 2',
                            },
                          ]}
                        />
                      )
                    }
                  >
                    🆕 Accordion Section
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={LeonieHero} canvas />)}
                  >
                    ⏳ Leonie Hero
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={TimelineSection}
                          heading="Title"
                          items={DEFAULT_TIMELINE_ITEMS}
                        />
                      )
                    }
                  >
                    🆕 Timeline Section
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={FooterSectionWrapper} />)
                    }
                  >
                    🆕 Footer Section
                  </button>
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="layers" className="bg-yellow-50 p-1 grid gap-1">
              <Accordion.Header>
                <Accordion.Trigger className="flex gap-2 justify-between w-full">
                  <span>Layers</span>
                  <IconChevronDown />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="grid gap-1">
                  <SecondaryButton
                    onClick={async () => {
                      try {
                        const clipboardText = await navigator.clipboard.readText();
                        const pastedData = JSON.parse(clipboardText);

                        if (pastedData.isCraftjsLayer) {
                          const targetNodeId = selected?.id || 'ROOT';

                          if (pastedData.data) {
                            let newTree;
                            if (pastedData.rootNodeId === 'ROOT') {
                              newTree = pastedData.data;
                            } else {
                              const currentTree = JSON.parse(query.serialize());
                              currentTree[targetNodeId].nodes.push(pastedData.rootNodeId);
                              newTree = {
                                ...currentTree,
                                ...pastedData.data,
                              };
                              newTree[pastedData.rootNodeId].parent = targetNodeId;
                            }

                            actions.deserialize(JSON.stringify(newTree));
                            actions.selectNode(pastedData.rootNodeId);
                          }

                          toast.success('Layer pasted successfully');
                        } else {
                          toast.error('Invalid layer data. Please paste a valid layer.');
                        }
                      } catch (error) {
                        console.error(error);
                        toast.error('Failed to paste layer. Please paste a valid layer.');
                      }
                    }}
                    className="text-sm"
                  >
                    <span>
                      paste component
                      <br />
                      <span className="normal-case text-000">
                        (select target paste layer first)
                      </span>
                    </span>
                  </SecondaryButton>
                  <Layers expandRootOnLoad renderLayer={Layer} />
                </div>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
          {selected?.settings && (
            <div className="bg-yellow-100 p-2">{React.createElement(selected.settings)}</div>
          )}
        </div>
      )}
    </div>
  );
};
