import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';

import { fadeInProps } from '../animations';
import { NewDesktopSection } from './NewDesktopSection';
import { NamedLink } from 'components';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { RouteName } from 'routing/routeConfiguration';
import { DEFAULT_SORTING } from 'util/constants';

export const TRENDING_NOW_ITEMS: {
  title: string;
  imgSrc: string;
  imgBlurSrc: string;
  linkParams: { name: RouteName; to: { search: string } };
}[] = [
  {
    title: 'Iconic Bags',
    imgSrc: 'https://cdn.thenold.com/home/BAGS.jpg',
    imgBlurSrc: 'https://cdn.thenold.com/home/BAGS.blur.jpg',
    linkParams: {
      name: 'SearchPage',
      to: { search: `category=bags&sortBy=${DEFAULT_SORTING}` },
    },
  },
  {
    title: 'Timeless RTW',
    imgSrc: 'https://cdn.thenold.com/home/CLOTHES.jpg',
    imgBlurSrc: 'https://cdn.thenold.com/home/CLOTHES.blur.jpg',
    linkParams: {
      name: 'SearchPage',
      to: { search: `category=clothing&sortBy=${DEFAULT_SORTING}` },
    },
  },
  {
    title: 'Accessories',
    imgSrc: 'https://cdn.thenold.com/home/ACCESSORIES.jpg',
    imgBlurSrc: 'https://cdn.thenold.com/home/ACCESSORIES.blur.jpg',
    linkParams: {
      name: 'SearchPage',
      to: { search: `category=accessories&sortBy=${DEFAULT_SORTING}` },
    },
  },
  {
    title: 'Shoes',
    imgSrc: 'https://cdn.thenold.com/home/SHOES.jpg',
    imgBlurSrc: 'https://cdn.thenold.com/home/SHOES.blur.jpg',
    linkParams: {
      name: 'SearchPage',
      to: { search: `category=shoes&sortBy=${DEFAULT_SORTING}` },
    },
  },
];

const NewTrendingNowSection = forwardRef<HTMLDivElement>((_, ref) => {
  const isDesktop = useMediaQueries({ viewport: 'small' });

  if (isDesktop) {
    return (
      <NewDesktopSection
        ref={ref}
        heading={
          <>
            <h3 className="text-1">
              <span className="bg-custom-green">Pre-loved</span>
            </h3>
            <h2 className="">Trending Now</h2>
          </>
        }
      >
        {TRENDING_NOW_ITEMS.map(item => (
          <NamedLink
            key={item.title}
            {...item.linkParams}
            className="min-w-[300px] flex-1 flex-shrink-0 relative font-syne uppercase"
          >
            <img src={item.imgSrc} alt={item.title} className="aspect-[3/4] w-full object-cover" />
            <div className="absolute bottom-0 left-0 right-0 font-syne font-bold text-center text-1 pb-4 px-2 ">
              <span>{item.title}</span>
            </div>
          </NamedLink>
        ))}
      </NewDesktopSection>
    );
  }

  return (
    <motion.div className="w-full py-5 grid gap-2" {...fadeInProps} ref={ref}>
      <div className="flex flex-col gap-1 items-center">
        <h3 className="font-syne text-0 font-bold uppercase bg-custom-green">Pre-loved</h3>
        <h2 className="font-syne text-3 md:text-5 font-bold uppercase">Trending Now</h2>
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        {TRENDING_NOW_ITEMS.map(item => (
          <NamedLink
            {...item.linkParams}
            className="grid gap-2 hover:no-underline font-syne uppercase"
            key={item.title}
          >
            <img src={item.imgSrc} alt={item.title} className="aspect-[3/4] w-full object-cover" />
            <h3 className="font-syne text-0 font-medium uppercase text-center underline !underline-offset-4">
              {item.title}
            </h3>
          </NamedLink>
        ))}
      </div>
    </motion.div>
  );
});

export default NewTrendingNowSection;
