import { motion } from 'framer-motion';
import { forwardRef, ReactNode, useMemo } from 'react';

import { fadeInProps } from '../animations';
import { Button, NamedLink } from 'components';
import { RouteName } from 'routing/routeConfiguration';

const NewBlackSection = forwardRef<
  HTMLDivElement,
  {
    sentences: ReactNode[];
    children?: ReactNode;
    ctaText: string;
    ctaLink: string | { name: RouteName; params: Record<string, string> };
  }
>(({ sentences, ctaText, ctaLink, children }, ref) => {
  const duration = 15;

  const text = (
    <div className="flex gap-7 md:gap-[100px] md:text-2 pr-7 whitespace-nowrap uppercase font-bold tracking-wider">
      {sentences.map(sentence => (
        <span>{sentence}</span>
      ))}
    </div>
  );

  const LinkComponent = useMemo(() => {
    return typeof ctaLink === 'string'
      ? ({ children }: { children: ReactNode }) => <a href={ctaLink}>{children}</a>
      : ({ children }: { children: ReactNode }) => <NamedLink {...ctaLink}>{children}</NamedLink>;
  }, [ctaLink]);

  return (
    <motion.div className="bg-black text-white py-10 w-full" {...fadeInProps} ref={ref}>
      <div className="flex flex-col gap-6 md:gap-10">
        <h2 className="font-syne text-3 md:text-5 font-bold uppercase text-center">{children}</h2>
        <div>
          <motion.div
            className="flex w-fit overflow-visible"
            animate={{
              x: ['0%', '-25%'],
            }}
            transition={{
              x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration,
                ease: 'linear',
              },
            }}
          >
            <div>{text}</div>
            <div>{text}</div>
            <div>{text}</div>
            <div>{text}</div>
          </motion.div>
        </div>
        <div className="flex justify-center">
          <Button className="!bg-black !text-white !border-white">
            <LinkComponent>{ctaText}</LinkComponent>
          </Button>
        </div>
      </div>
    </motion.div>
  );
});

export default NewBlackSection;
