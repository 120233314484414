import { useNode } from '@craftjs/core';
import { ComponentProps } from 'react';

import { CarouselSectionSettings } from './CarouselSectionSettings';
import { NewCarouselSection } from 'containers/CustomLandingPage/components/NewCarouselSection';

function CarouselSectionWrapper(props: ComponentProps<typeof NewCarouselSection>) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return <NewCarouselSection {...props} ref={ref => ref && connect(drag(ref))} />;
}

CarouselSectionWrapper.craft = {
  displayName: 'Carousel',
  props: {
    title: 'Carousel Title',
    subtitle: 'Carousel Subtitle',
    collectionId: 1,
    buttonText: 'View All',
    buttonLinkType: 'collection',
    buttonCustomPath: '',
    items: [
      {
        imageUrl: 'https://example.com/image.jpg',
        imageBlurUrl: 'https://example.com/image-blur.jpg',
        title: 'Item Title',
        link: '',
        whiteText: false,
      },
    ],
  },
  related: {
    settings: CarouselSectionSettings,
  },
};

export default CarouselSectionWrapper;
