import { motion, useScroll } from 'framer-motion';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { fadeInProps } from '../animations';
import { NewDesktopSection } from './NewDesktopSection';
import { NamedLink } from 'components';
import { useMediaQueries } from 'hooks/useMediaQueries';

const ICONS: Array<{
  title: string;
  imgSrc: string;
  imgBlurSrc: string;
  linkParams: {
    name: 'ProfilePage';
    params: { id: string };
  };
}> = [
  {
    title: 'MARIA GUARDIOLA',
    imgSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__maria_compressed.webp',
    imgBlurSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__maria_blur.webp',
    linkParams: {
      name: 'ProfilePage',
      params: {
        id: '660aeed8-a6fe-4871-98c2-3f1d96d43a87',
      },
    },
  },
  {
    title: 'DANIELLE COPPERMAN',
    imgSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__danielle_compressed.webp',
    imgBlurSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__danielle_blur.webp',
    linkParams: {
      name: 'ProfilePage',
      params: {
        id: '65f2fe35-15e2-4fa0-a8ac-f4a162dbe486',
      },
    },
  },
  {
    title: 'IVONA ZUPET',
    imgSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__ivona_compressed.webp',
    imgBlurSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__ivona_blur.webp',
    linkParams: {
      name: 'ProfilePage',
      params: {
        id: '6603fac8-062f-43da-81e0-f7a1497e592b',
      },
    },
  },
  {
    title: 'MOLLIE',
    imgSrc: 'https://cdn.thenold.com/home/icons/mollie.jpg',
    imgBlurSrc: 'https://cdn.thenold.com/home/icons/mollie.blur.jpg',
    linkParams: {
      name: 'ProfilePage',
      params: {
        id: '66053581-9a48-4b01-824b-9f365dc920b1',
      },
    },
  },
  {
    title: 'LILY',
    imgSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__lily_compressed.webp',
    imgBlurSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__lily_blur.webp',
    linkParams: {
      name: 'ProfilePage',
      params: {
        id: '65f86f23-33af-4593-ac87-81867cc58e6e',
      },
    },
  },
  {
    title: 'AMELIA',
    imgSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__amelia_compressed.webp',
    imgBlurSrc: 'https://cdn.thenold.com/images/2024-10-30_12-47-17__amelia_blur.webp',
    linkParams: {
      name: 'ProfilePage',
      params: {
        id: '65f9106d-fdf0-4394-a538-9835daf49c95',
      },
    },
  },
];

export const NewIconsSection = forwardRef<HTMLDivElement>((_, ref) => {
  const isDesktop = useMediaQueries({ viewport: 'small' });

  return isDesktop ? <NewIconsSectionDesktop ref={ref} /> : <NewIconsSectionMobile ref={ref} />;
});

const NewIconsSectionDesktop = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <NewDesktopSection
      ref={ref}
      heading={
        <>
          Game changer
          <br />
          wardrobes
        </>
      }
    >
      {ICONS.map(icon => (
        <NamedLink
          key={icon.title}
          {...icon.linkParams}
          className="w-[300px] flex-shrink-0 relative"
        >
          <img src={icon.imgSrc} alt={icon.title} className="aspect-[3/4] w-full object-cover" />
          <div className="text-white absolute bottom-0 left-0 right-0 font-syne font-bold text-center text-1 pb-4 px-2 ">
            {icon.title}
          </div>
        </NamedLink>
      ))}
    </NewDesktopSection>
  );
});

const NewIconsSectionMobile = forwardRef<HTMLDivElement>((_, ref) => {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollX } = useScroll({ container: scrollRef });

  const itemCenterX = itemRefs.current.map((item, index) => {
    const containerWidth = scrollRef.current?.getBoundingClientRect().width || 0;
    const rect = item?.getBoundingClientRect();
    if (!rect) return { scale: 0, rotateY: 0 };

    const xDistanceFromCenter = rect?.left - containerWidth / 2 + rect.width / 2;
    const maxDistance = containerWidth * 0.8;
    const distance = Math.min(Math.abs(xDistanceFromCenter), maxDistance);
    const percentage = distance / maxDistance;

    // Calculate scale: 1.25 when centered, 0.75 when at max distance
    const scale = 1.1 - 0.5 * percentage;
    const rotateY = 7 * percentage * (xDistanceFromCenter < 0 ? -1 : 1);
    return { scale, rotateY, itemLeft: rect.left };
  });

  const [, setX] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setX(0);
    }, 100);

    return scrollX.onChange(value => {
      setX(value);
    });
  }, [scrollX]);

  useEffect(() => {
    scrollRef.current?.scrollTo({
      left: itemCenterX[0]?.itemLeft,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCenterX.length]);

  return (
    <motion.div className="flex flex-col pt-5 pb-6 w-full" {...fadeInProps} ref={ref}>
      <h2 className="font-syne text-3 md:text-5 font-bold uppercase text-center">
        Game changer
        <br /> wardrobes
      </h2>
      <div
        ref={scrollRef}
        className="snap-x flex w-full overflow-x-auto px-[30vw] py-5 [perspective:200px]"
      >
        {ICONS.map((icon, index) => (
          <motion.div
            key={index}
            ref={el => (itemRefs.current[index] = el)}
            className="relative flex-shrink-0 w-[40vw] aspect-[3/4] snap-proximity snap-center"
            animate={{
              scale: itemCenterX?.[index]?.scale,
              rotateY: itemCenterX?.[index]?.rotateY,
              transition: {
                type: 'spring',
                damping: 50,
                stiffness: 200,
              },
            }}
          >
            <img
              src={icon.imgSrc}
              alt={icon.title}
              className="w-full h-full object-cover rounded-xl"
            />
            <div className="absolute inset-0 text-center tracking-widest text-white font-semibold font-syne p-2 grid place-items-center">
              <span className="w-min">{icon.title}</span>
            </div>
            <NamedLink {...icon.linkParams} className="absolute inset-0 w-full h-full" />
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
});
