import { useNode, UserComponent } from '@craftjs/core';
import { useMediaQuery } from 'usehooks-ts';

import { breakpoints } from '../../../constants/breakpoints';
import { ImageSettings } from './ImageSettings';
import { ImageLazy } from 'components';
import { cn } from 'util/cn';

type ImageProps = {
  src: string;
  blurSrc?: string;
  alt?: string;
  width?: number | 'full';
  height?: number;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  objectPosition?: string;
  responsiveMinScreen?: string;
  responsiveMaxScreen?: string;
};

export const Image: UserComponent<ImageProps> = ({
  src,
  blurSrc,
  alt = '',
  width = 'full',
  height,
  objectFit = 'cover',
  objectPosition = 'center',
  responsiveMinScreen,
  responsiveMaxScreen,
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  const minScreenValue = responsiveMinScreen
    ? breakpoints[responsiveMinScreen] || responsiveMinScreen
    : '0px';
  const maxScreenValue = responsiveMaxScreen
    ? breakpoints[responsiveMaxScreen] || responsiveMaxScreen
    : 'Infinity';

  const isAboveMin = useMediaQuery(`(min-width: ${minScreenValue})`);
  const isBelowMax = useMediaQuery(
    maxScreenValue === 'Infinity' ? '(min-width: 0px)' : `(max-width: ${maxScreenValue})`
  );

  if (!isAboveMin || !isBelowMax) {
    return null;
  }

  return (
    <ImageLazy
      ref={ref => ref && connect(drag(ref))}
      src={src}
      blurSrc={blurSrc}
      alt={alt}
      className={cn('h-full', width === 'full' && 'w-full')}
      style={{
        width: typeof width === 'number' ? `${width}px` : undefined,
        height: height ? `${height}px` : undefined,
        objectFit,
        objectPosition,
      }}
    />
  );
};

Image.craft = {
  displayName: 'Image',
  related: {
    settings: ImageSettings,
  },
};
