import { useNode } from '@craftjs/core';
import React, { ComponentProps } from 'react';

import { NewFeaturedSection } from 'containers/CustomLandingPage/components/NewFeaturedSection';

function FeaturedSectionWrapper(props: ComponentProps<typeof NewFeaturedSection>) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return <NewFeaturedSection {...props} ref={ref => ref && connect(drag(ref))} />;
}

FeaturedSectionWrapper.craft = {
  displayName: 'Featured',
};

export default FeaturedSectionWrapper;
