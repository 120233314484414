import { useNode } from '@craftjs/core';
import { ComponentProps } from 'react';

import NewLookingForSection from 'containers/CustomLandingPage/components/NewLookingForSection';

function LookingForSectionWrapper(props: ComponentProps<typeof NewLookingForSection>) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return <NewLookingForSection {...props} ref={ref => ref && connect(drag(ref))} />;
}

LookingForSectionWrapper.craft = {
  displayName: 'Looking For',
};

export default LookingForSectionWrapper;
