import { useNode } from '@craftjs/core';
import { ComponentProps } from 'react';

import { NewIconsSection } from 'containers/CustomLandingPage/components/NewIconsSection';

function IconsSectionWrapper(props: ComponentProps<typeof NewIconsSection>) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return <NewIconsSection {...props} ref={ref => ref && connect(drag(ref))} />;
}

IconsSectionWrapper.craft = {
  displayName: 'Icons',
};

export default IconsSectionWrapper;
