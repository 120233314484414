import { Editor, Frame } from '@craftjs/core';
import { Element } from '@craftjs/core';
import { decode } from 'js-base64';
import { useLocation, useParams } from 'react-router-dom';

import {
  LayoutSingleColumn,
  MobileBottomNavigation,
  NamedLink,
  NamedRedirect,
  Page,
  PrimaryButton,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { AccordionSection } from './components/AccordionSection';
import { Button } from './components/Button';
import { Container } from './components/Container';
import { HeroSection } from './components/HeroSection';
import { Image } from './components/Image';
import { LandingSection } from './components/LandingSection';
import { LeonieHero } from './components/LeonieHero';
import { ListingsSection } from './components/ListingsSection';
import { ShowcaseCarousel } from './components/ShowcaseCarousel';
import { ShowcaseCarouselItem } from './components/ShowcaseCarouselItem';
import { Spacer } from './components/Spacer';
import { Text } from './components/Text';
import TimelineSection from './components/TimelineSection';
import {
  useCreateEditablePage,
  useEditablePagesQueryParams,
  useGetEditablePage,
  useGetEditablePageVersions,
} from './EditablePages.hooks';
import { useIsNoldUser } from './EditablePages.hooks';
import css from './EditablePages.module.css';
import { RenderNode } from './RenderNode';
import { Toolbox, useEnableLinksState } from './Toolbox';
import BlackSectionWrapper from './wrappers/BlackSectionWrapper';
import CarouselSectionWrapper from './wrappers/CarouselSectionWrapper';
import FeaturedSectionWrapper from './wrappers/FeaturedSectionWrapper';
import { FollowingSectionWrapper } from './wrappers/FollowingSectionWrapper';
import FooterSectionWrapper from './wrappers/FooterSectionWrapper';
import IconsSectionWrapper from './wrappers/IconsSectionWrapper';
import InstagramSectionWrapper from './wrappers/InstagramSectionWrapper';
import ListingSectionWrapper from './wrappers/ListingSectionWrapper';
import LookingForSectionWrapper from './wrappers/LookingForSectionWrapper';
import { MatrixSectionWrapper } from './wrappers/MatrixSectionWrapper';
import SampleSaleSectionWrapper from './wrappers/SampleSaleSectionWrapper';
import TrendingNowSectionWrapper from './wrappers/TrendingNowSectionWrapper';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { cn } from 'util/cn';

const EditablePages = () => {
  const { pageId = '' } = useParams<{ pageId: string }>();
  const { isNoldEmail, isLoading } = useIsNoldUser();

  if (isLoading) {
    return null;
  }

  if (pageId === 'list' && isNoldEmail) {
    return <ListEditablePages />;
  }

  return <EditPage pageId={pageId} />;
};

const EditPage = ({ pageId }: { pageId: string }) => {
  const scrollingDisabled = useIsScrollingDisabled();
  const location = useLocation();
  const { isNoldEmail, isLoading: isLoadingUser } = useIsNoldUser();
  const isEditing = !!location.search?.includes('edit=true') && isNoldEmail;

  const [{ version }] = useEditablePagesQueryParams();
  const { data: page, isLoading, isError, error } = useGetEditablePage(pageId, version);

  const [enableLinks] = useEnableLinksState();

  if (!pageId) {
    return null;
  }

  if (isLoading || isLoadingUser) {
    return null;
  }

  if (isError && !isEditing) {
    return <NamedRedirect name="LandingPage" />;
  }

  if (isError && isEditing) {
    return (
      <div className="text-red-500 w-screen h-screen p-2 grid place-content-center text-center">
        Error: {JSON.stringify(error)}
      </div>
    );
  }

  console.log(isEditing, page);

  if (!page) {
    return <NamedRedirect name="LandingPage" />;
  }

  return (
    <Page title="THE NOLD" scrollingDisabled={scrollingDisabled}>
      <CustomEditor isEditing={isEditing}>
        <div className="grid grid-cols-[1fr_auto] w-screen h-screen">
          <LayoutSingleColumn
            topbar={<TopbarContainer />}
            footer={<MobileBottomNavigation />}
            footerClassName="w-full overflow-x-hidden"
            className="w-full"
            mainColumnClassName={cn(
              { [css.linksDisabled]: isEditing && !enableLinks },
              'w-full overflow-x-hidden'
            )}
          >
            <Frame json={isEditing ? undefined : decode(page?.content ?? '')}>
              <Element
                canvas
                is="div"
                className="craftjs-renderer min-h-px w-full overflow-x-hidden"
                id="root"
              ></Element>
            </Frame>
          </LayoutSingleColumn>
          {isEditing && <Toolbox page={page} />}
        </div>
      </CustomEditor>
    </Page>
  );
};

const ListEditablePages = () => {
  const scrollingDisabled = useIsScrollingDisabled();
  const { data: editablePages } = useGetEditablePageVersions();
  const createEditablePage = useCreateEditablePage();

  return (
    <Page title="THE NOLD | Editable Pages" scrollingDisabled={scrollingDisabled}>
      <div className="grid grid-cols-1 gap-2">
        <h2 className="p-2 font-bold font-syne text-3">THE NOLD | Editable Pages</h2>
        <form
          className="flex flex-col gap-2 p-2 items-start bg-gray-100"
          onSubmit={e => {
            e.preventDefault();
            const path = e.currentTarget.pageRoute.value;
            const name = e.currentTarget.pageName.value;
            if (path && name) {
              createEditablePage.mutate(
                { path },
                {
                  onSuccess: () => {
                    e.currentTarget.reset();
                  },
                }
              );
            }
          }}
        >
          <h1 className="font-bold uppercase font-syne text-2">Create new page</h1>
          <div className="flex gap-2">
            <label>
              Page route (path)
              <input className="p-1 bg-white" placeholder="page-route" id="pageRoute" />
            </label>
            <label>
              Page name
              <input className="p-1 bg-white" placeholder="Page Name" id="pageName" />
            </label>
          </div>
          <PrimaryButton type="submit" disabled={createEditablePage.isLoading}>
            {createEditablePage.isLoading ? 'Creating...' : 'Create'}
          </PrimaryButton>
        </form>
        <div className="grid grid-cols-1 gap-1 p-2">
          <h1 className="font-bold uppercase font-syne text-2 mb-1">Pages list</h1>
          {editablePages?.map(page => (
            <NamedLink
              key={page.path}
              name="EditablePages"
              params={{ pageId: page.path }}
              to={{ search: `?edit=true` }}
              className="p-2 bg-gray-100 hover:bg-gray-200 transition-colors font-bold"
            >
              {page.path} (active version: {page.activeVersion}, latest version:{' '}
              {page.versions?.[0]})
            </NamedLink>
          ))}
        </div>
      </div>
    </Page>
  );
};

export const CustomEditor = ({
  isEditing = false,
  children,
}: {
  isEditing?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Editor
      resolver={{
        Button,
        Container,
        HeroSection,
        LandingSection,
        ListingsSection,
        ShowcaseCarousel,
        ShowcaseCarouselItem,
        Spacer,
        Text,
        Image,
        CarouselSectionWrapper,
        FeaturedSectionWrapper,
        FollowingSectionWrapper,
        IconsSectionWrapper,
        InstagramSectionWrapper,
        LookingForSectionWrapper,
        MatrixSectionWrapper,
        BlackSectionWrapper,
        SampleSaleSectionWrapper,
        TrendingNowSectionWrapper,
        ListingSectionWrapper,
        AccordionSection,
        LeonieHero,
        TimelineSection,
        FooterSectionWrapper,
      }}
      onRender={isEditing ? RenderNode : ({ render }) => render}
      enabled={isEditing}
    >
      {children}
    </Editor>
  );
};

export default EditablePages;
